import { catchError, combineLatest, concatMap, EMPTY, filter, firstValueFrom, forkJoin, from, map, mergeMap, of, throwError } from "rxjs";
import { ArrayStore, BaseSimpleEvent, Store, Vasat, VasatConfig, VasatError } from "vasat";
import { PlanStub, User } from "./models";

class RegionEvent extends BaseSimpleEvent{}
class UserEvent extends BaseSimpleEvent{}

export default class AppService {

    static myInstance = null;
    vasat;

    regionsStore = new ArrayStore(RegionEvent)
    selectedRegionStore = new Store(RegionEvent)
    currentUser = new Store(UserEvent)
    captchaKey = ''
    constructor() {
 
        var providedUrl = process.env.REACT_APP_VASAT_URL;
        //console.log("ENTER AppService constructor providedUrl ",providedUrl);

        var vasatUrl = "https://vasat.surfable.co"
        if (providedUrl) {
            vasatUrl = providedUrl
        } else if (process.env.NODE_ENV == 'production') {
            vasatUrl = "https://vasat.surfable.co"
        } else {
            //vasatUrl = "https://surferapi.correllink.com"
            vasatUrl = "https://vasat.surfable.co"
            //vasatUrl = "http://localhost:9000"
        }
        
        this.vasat = new Vasat( new VasatConfig(
            vasatUrl,
            "surfer",
            "",
            true
        ), null, User );



        this.vasat['_http'] = function(meth, url,obj,args, tryedRefresh) {
          
            let a = args || {} 
            let reqOpt = {
                credentials: 'include',
                method: meth,
            }
            let headers = {
              
            }
           
            if(this.token)
                headers["Authorization"] = 'bearer ' + this.token
    
            if(obj) {
                reqOpt.body = JSON.stringify(obj)
                headers["Content-Type"] = 'application/json' 
            }            
            reqOpt.headers = headers
            return from(fetch(this._getURL(url),reqOpt)).pipe(
                concatMap(res => {
                    // Check for 400 and 500 server errors and throw exception accordingly
                    if (res.status >= 400) {
                        return from(res.clone().json()).pipe(
                            concatMap(response => {
                                // Trigger _errorEvent in case client app wants to do something special with errors. Specially with not Logged in responses
                                var error = new VasatError(response, "Error")
    
                                if((response.status == 40014 || response.status == 40019 || response.status == 40022) && !tryedRefresh){ 
                                    return this.refreshToken(error).pipe(concatMap(_ => 
                                        this._http(meth,url,obj,args,true)
                                    ))
                                }
                                else{
                                    this._errorEvent.next(error)
                                    return throwError( error )
                                }
                            })
                        )
                    } else {
                        // fetch request return custom response so we need to parse it to return vasat response
                        return from(res.clone().json())
                    }  
                }),
                catchError(err => {
                    // Gracefully handling fetch or other errors and convert them into VasatError
                    let ve = null
                    if(err instanceof VasatError) {
                        ve = err 
                    } else if ( err['status'] && err['message'] && err['version'] ) {
                        ve = new VasatError(err,null)
                    } else if (err instanceof TypeError) {
                        ve = new VasatError({status:99001,message:""+err,version:"",time:new Date().getTime()},err)
                    } else {
                        ve = new VasatError({status:99002,message:"Unknown Error: "+err,version:"",time:new Date().getTime()},err)
                    }
    
                    this._errorEvent.next(ve)
                    return throwError( ve )
                })
            )
        }
        // Init data
        this.initData();
    }

    /**
     * @returns {AppService}
     */
    static getInstance() {
        //console.log("ENTER AppService getInstance myInstance", AppService.myInstance)
        if (AppService.myInstance == null) {
            AppService.myInstance = new AppService();
        }

        // Load session
        AppService.myInstance.vasat.localSession()
        return this.myInstance;
    }

    initData() {
        forkJoin( {
            regions: this.fetchRegions().pipe(map(res => this.regionsStore.values = res)),
            user: (this.vasat.localSession() != null) ? this.vasat.refreshMeUser().pipe(map(res => this.currentUser.value = res)) : EMPTY
        } ).subscribe();

        this.fetchRegions().subscribe(res => this.regionsStore.values = res);
    }

    /******************************* User *******************************/
    loginUser(email, password) {

        // Convert loginWithUsername obeservable to a promise
        return firstValueFrom(
            this.vasat.loginWithUsername(email, password, true).pipe(mergeMap(res => this.refreshCurrentUser()))
        )
    }

    logoutUser() {
        return this.vasat.logout().toPromise();
    }

    resetPasswordRequest(email) {
        return this.vasat.resetPasswordRequest(email).toPromise();
    }

    resetPasswordTokenCheck(token) {
        return this.vasat.resetPasswordTokenCheck(token).toPromise();
    }

    resetPassword(token, newPass) {
        return this.vasat.resetPassword(token, newPass).toPromise();
    }

    registerNewUser(name, email, password, capcha,captchKey) {
        console.log('TRYING!')
        return this.vasat.signup(email, password, email, {name:name,email:email,captcha:capcha,captcha_key:captchKey || this.captchaKey}).pipe(mergeMap(res => this.refreshCurrentUser()));
    }

    /******************************* Data *******************************/
    fetchArticle(articleUid) {
        return this.vasat.searchByObjectString("Article").equals("id", articleUid)
            .query()
            .pipe(
                map(res => (res.items && res.items.length > 0) ? res.items[0] : {} )
            );
    }

    fetchRegions() {
        return this.vasat.searchByObjectString("Region")
            .sortBy("order")
            .query()
            .pipe(
                map(res => res.items)
            );
    }

    getRegions() {
        return this.regionsStore.state.pipe(filter(res => res && res.length > 0))
    }

    searchRegionByUid(regionUid) {
        //console.log("ENTER searchRegionByUid")
        return this.regionsStore.state.pipe(
            filter(res => res && res.length > 0),
            map(res => res.find(ele => ele.id == regionUid))
        );
    }
    getCurrentAccessToken() {
        return this.vasat?.localSession()?.access_token;
    }

    getCurrentUserInMemory() {
        return this.vasat?.localSession()?.user;
    }

    refreshCurrentUser() {
        return this.vasat.refreshMeUser().pipe(map(res => {
            this.currentUser.value = res
            return res
        }))
    }
    /******************************* Subscriptions *******************************/

    getPublicPlans(includeTrial = true) {
        return firstValueFrom(
            this.vasat.searchByObject(PlanStub).query().pipe(
                map(search => search.items),
                map(result => {
                    // We need to iterate through the list of PlanStus (which are the stripe list of Prices) and check for a meta field name hasTrial to add trial plans attached to the same Price.
                    // To make a Price/Plan as trial go to Stripe dashboard select the Product/Price and add a meta value with hasTrial=true
                    var trailPlans = []
                    if (includeTrial) {
                        result.forEach(item => {
                            if (item.meta && item.meta["hasTrial"]) trailPlans.push({id:item.id,interval:"month",price:0,usageType:"trial"})
                        })
                    }
                    return result.concat(trailPlans)
                })
            )
        )
    }

    getPlanById(planId) {
        return firstValueFrom(
            this.vasat.searchByObject(PlanStub).equals("id",planId) .query().pipe(map(search => {
                if (search.items && search.items.length > 0) {
                    return search.items[0];
                } else {
                    throw new Error('Valid token not returned');
                }
            }))
        )
    }

    /**
     * Service returns a list of subscriptions but each user should only have one or none. So we simply return the first item from the array.
     * @returns 
     */
    getSubscription() {
        return firstValueFrom(this.getSubscriptionObs())
    }

    getSubscriptionObs() {
        return this.vasat.api("/subscribe/mySub").pipe(
            map(res => (res.payload && res.payload.length > 0) ? res.payload[0] : null)
        )
    }

        /*
    getSubscription() {
        return firstValueFrom(this.vasat.api("/subscribe/mySub"));
    }
    */

    cancelSubscription() {
        return firstValueFrom(this.vasat.api("/subscribe/mySub", "delete"))
    }

    updateSubscriptionPayment(subId, paymentMethodId) {
        var data = {
            payment:paymentMethodId
        };
        return this.vasat.api("/update/mySub/"+subId, "post", data);
    }

    createSubscription(priceId, paymentMethodId, isTrial=true, currency="USD", couponId=null) {
        var data = {
            price:priceId,
            payment:paymentMethodId,
            currency: currency,
            isTrial:isTrial
        };

        if (couponId) data["couponId"] = couponId;

        return this.vasat.api("/subscribe/mySub", "post", data);
    }

    getPaymentMethod() {
        return firstValueFrom(this.vasat.api("/subscribe/card").pipe(map(res => {
            return res.results;
        })))
    }

    getStripeCustomer() {
        return firstValueFrom(this.vasat.api("/subscribe/myCustomer"))
    }

    createPaymentMethod(cardNumber, cardMonth, cardYear, cardCvc, cap) {
        var data = {
            captcha:cap,
            captcha_key:this.captchaKey,
            paymentType:"card",
            card: {
                number: cardNumber,
                cvc: cardCvc,
                expMon: parseInt(cardMonth),
                expYear: parseInt(cardYear)
            }
        };
        return this.vasat.api("/subscribe/card", "post", data).pipe(map(res => res.message));
    }

    getInvoices() {
        return this.vasat.api("/subscribe/invoice")
    }

    getPromotionCodes(promoCode) {
        return this.vasat.api("/subscribe/coupon/"+promoCode).pipe(map(res => res.results ))
    }


    /*****************************************************************************/

}