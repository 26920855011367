import React, { Component } from "react";
import Header from "./Header";
import "./MySubscriptionPage.css";
import { BsArrowLeft } from "react-icons/bs";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppService from "../../commons/AppService";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';

export default class ProfilePage extends Component {

  service = AppService.getInstance();
  
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
      isNameChanged: false,
      isPasswordChanged: false,
      oldPass: "",
      newPass: "",
      currentUser: null
    };
  }
  componentDidMount() {
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.setState({
      isLoading: true,
    });

    this.service.currentUser.state.subscribe(
      res => {
        this.setState({
          isLoading: false,
          currentUser: res
        });
      }
    )
  }

  handleDialogOption(isOk) {
    this.setState({
      isDialogOpen: false,
    });
    // If positive, in this case unsubscribe the user
    if (isOk) {
      
    }
  }

  saveUser = () => {
    this.setState({
      isLoading: true,
    });
    this.state.currentUser.save().subscribe(
      succ => {
        //console.log("ENTER saveUser succ",succ);
        this.service.currentUser.value = succ;
        this.setState({
          isLoading: false,
          isNameChanged: false
        });
        toast.success("Profile has been changed.");
      }, err => {
        this.setState({
          isLoading: false,
        });
        toast.error( (err.message) ? err.message : err );
      }
    )
  };

  chagePassword = () => {
    this.setState({
      isLoading: true,
    });

    this.state.currentUser.changePassword(this.state.oldPass,this.state.newPass).subscribe(
      succ => {
        //console.log("ENTER chagePassword succ",succ);
        this.setState({
          isLoading: false,
          isPasswordChanged: false,
          oldPass: "",
          newPass: "",
        });
        toast.success("Password has been changed.");
      }, err => {
        this.setState({
          isLoading: false,
        });
        toast.error( (err.message) ? err.message : err );
      }
    )
  };

  render() {
    return (
      <div>
        <Header />
        <div className="my-subscription-header">
          <div className="my-subs-heading-align">
            <div className="back-to-text" style={{ marginRight: "20px" }}>
              {" "}
              <BsArrowLeft
                onClick={() => this.props.history.push("/")}
                // size="30px"
                style={{
                  position: "relative",
                  fontSize: "30px",
                  top: "10px",
                  cursor: "pointer",
                }}
              />
              Back to Home
            </div>
            <p className="my-subs-heading"> My Profile</p>
          </div>
        </div>
        <div className="card-align">
          <div className="my-subscription-card">
            
            { (this.state.currentUser != null) ? (
              <div className="flex-row flex-center pt-1 pb-1">
                <div className="flex-column flex-align-start" style={{maxWidth:'400px'}}>
                  <span className="f-xl">Details</span>
                  <TextField 
                    label="Name" 
                    className="mt-1 w-100" 
                    defaultValue={this.state.currentUser?.name}
                    onChange={(e) => this.setState({isNameChanged: true, currentUser: Object.assign(this.state.currentUser, {name:e.target.value})}) }
                  />
                  <TextField 
                    label="Email" 
                    className="mt-1 w-100" 
                    defaultValue={this.state.currentUser?.email} 
                    helperText="*Your email address is your unique identifier for your premium membership, if you'd like to change it, email us at contact@surfable.com. Thanks!"
                    disabled/>
                  <Button variant="contained" className="mt-1" onClick={this.saveUser} disabled={!this.state.isNameChanged}>Save</Button>
                </div>
                <div style={{width:'40px'}}></div>
                <div className="flex-column flex-align-start">
                  <span className="f-xl">Change password</span>
                  <TextField label="Old Password" className="mt-1" type="password" value={this.state.oldPass} onChange={(e) => this.setState({oldPass: e.target.value})}/>
                  <TextField label="New Password" className="mt-1" type="password" value={this.state.newPass} onChange={(e) => this.setState({newPass: e.target.value, isPasswordChanged:true})}/>
                  <Button variant="contained" className="mt-1 mb-1" onClick={this.chagePassword} disabled={!this.state.isPasswordChanged}>Change</Button>
                </div>
              </div>
            ) : (<div className="flex-row flex-center"><CircularProgress/></div>) }
          
          </div>
        </div>
        <Dialog
          open={this.state.isDialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Unsubscribe:"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you would like to unsubscribe?.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleDialogOption(true)}>Ok</Button>
            <Button onClick={() => this.handleDialogOption(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
        {this.state.isLoading ? (
          <div className="sweet-loading">
            <ClipLoader size={50} color={"#ffffff"} loading={true} />
          </div>
        ) : null}
      </div>
    );
  }
}
