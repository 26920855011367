import React, { Component } from "react";
import Header from "../Header";
import "../SubscriptionPage.css";
import "./Stripe.css";
import left_arrow from "../../../assets/images/left-arrow.svg";
import {Helmet} from "react-helmet";
import AppService from "../../../commons/AppService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { mergeMap } from "rxjs";
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import PaymentMethod from  "../stripe/PaymentMethod"

export default class Stripe extends Component {

  service = AppService.getInstance();

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isLoadingPayments: false,
      planDetail: null,
      paymentMethods: [],
      selectedPaymentId: "new",
      cardNumber: "",
      cardName: "",
      cardMonth: "",
      cardYear: "",
      cardCvc: ""
    };
    this.goBack = this.goBack.bind(this);
    
  }
  componentDidMount() {
    //console.log("ENTER componentDidMount",this.props.match.params)
    this.getPlan(this.props.match.params.planId);
    this.getUserPaymentMethods();

  }

  getPlan(planId) {
    this.setState({
      isLoading: true,
      planDetail: null
    });

    this.service.getPlanById(planId)
      .then(res => {
        this.setState({
          isLoading: false,
          planDetail: res
        });
      })
      .catch(error => {
        this.setState({
          isLoading: false,
          planDetail: null
        });
        toast.error("Oops Something Went Wrong" + ((error.message) ? error.message : error) );
      })
  }

  getUserPaymentMethods() {
    this.setState({
      isLoadingPayments: true,
      paymentMethods: {}
    });

    this.service.getPaymentMethod()
      .then(methods => {
        //console.log("ENTER getUserPaymentMethods ",methods)
        this.setState({
          isLoadingPayments: false,
          paymentMethods: methods
        });
      }).catch(error => {
        this.setState({
          isLoadingPayments: false,
          paymentMethods: []
        });
        toast.error("Oops Something Went Wrong" + ((error.message) ? error.message : error) );
      })
  }

  createSubscription() {
    this.setState({
      isLoadingPayments: true
    });
    // If new card then create the payment method in stripe and use it to create the subscription.
    var opObservable = (this.state.selectedPaymentId == "new") 
      ? this.service.createPaymentMethod(this.state.cardNumber, this.state.cardMonth, this.state.cardYear, this.state.cardCvc)
      .pipe(
        mergeMap(res => this.service.createSubscription(this.state.planDetail.id, res))
      ) 
      : this.service.createSubscription(this.state.planDetail.id, this.state.planDetail.selectedPaymentId);
    
    opObservable.subscribe({
      next: (succ) => {
        toast.success("You have subscribed.")
      },
      error: (error) => {
        toast.error("Oops Something Went Wrong" + ((error.message) ? error.message : error) );
        this.setState({
          isLoadingPayments: false
        });
      },
      complete: () => {
        this.props.history.push("/");
        this.setState({
          isLoadingPayments: false
        });
      }
    })


    
  }

  cardChanged(newValue) {
    //console.log("ENTER cardChanged ",newValue)
    this.setState({
      selectedPaymentId: newValue
    })
  }

  goBack() {
    this.props.history.goBack();
  }

  numericKeyPress(event) {
    //console.log("ENTER event",event)
    if (event.charCode < 48 || event.charCode > 57) event.preventDefault();
  }

  renderLocation(){
    if(this.props.match.params.usageType=='trial') {
    return   <title>Free Trial | Surfable Maps</title>;    
    } else {
      return <title>Subscription | Surfable Maps</title>; 

    } 
  }
  renderMeta(){
    if(this.props.match.params.usageType=='trial') {
      return   <meta name='description' content='SURFER Forecast Premium Access Free Trial'></meta>;    
      } else {
        return <meta name='description' content='SURFER Forecast Premium Subscription Pricing'></meta>;
  
      } }

  render() {

    return (
      <div>
        <Helmet>
          {this.renderLocation()}
          {this.renderMeta()}
        </Helmet>
        <Header />
        <div>
          <div className="stripe-container">
            <div className="stripe-main-container">
              <div className="stripe-main">
                <div className="stripe-left-side">
                  <div className="subs-left-arrow-align">
                    <img
                      src={left_arrow}
                      onClick={() => this.goBack()}
                      className="subs-left-arrow"
                    />
                    <p className="subscription-top-text">Back to Pricing</p>
                  </div>

                  {(!this.state.planDetail) ? null : (
                    <div className="subs-card">
                      <div className="subs-card-align">
                        <div className="cards">
                          <div className="stripe-left-cards-wrap">
                            <div className="stripe-first-card">
                              <p className="stripe-plan-subtext">
                                Plan Selected{" "}
                              </p>
                              <p className="stripe-plan-title">
                                $ { (this.props.match.params.usageType=='trial') ? '0' : this.state.planDetail.price }
                                <span
                                  style={{
                                    fontSize: "0.5em",
                                    fontFamily: "Source-REg",
                                    textAlign: "center",
                                    color: "",
                                  }}
                                >
                                  {" "}
                                  / { (this.props.match.params.usageType=='trial') ? 'Free Trial' : this.state.planDetail.interval}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <p className="subs-left-title">
                    {" "}
                    <b>Methodology</b>
                  </p>
                  <p className="subs-left-subtext">
                    The SURFER Maps technology utilizes a series of nested
                    numerical grids in order to provide predictive data for the
                    7-day nearshore map forecasts. These forecasts refresh daily
                    at 3-hourly intervals.
                  </p>
                  <br />
                  <p className="subs-left-subtext">
                    We’re using US NOAA wave models to forecast waves at the
                    global, regional and higher resolution nearshore
                    transformation models. Additionally, we’re using some deeper
                    modeling developed by the US Army Corps of Engineers for
                    visual presentation.
                  </p>
                  <br />
                  <p className="subs-left-subtext">
                    These maps are reminiscent of the Swellwatch and Wetsand
                    maps. (The colors represent the relative wave height OR
                    period along the shoreline)
                  </p>
                  <br />
                  <p className="subs-left-subtext">Free:</p>
                  <br />
                  <p className="subs-left-subtext">
                    We've provided free 7-Day Wind Maps
                  </p>
                  <br />
                  <p className="subs-left-subtext">
                    We require a credit card to sign up for a free trial, but you won't be charged during the trial period.  We will will send a reminder email to you a few days before your subscription starts. If you're not happy with your subscription, you can cancel anytime.
                  </p>
                  <br />
                  <p className="subs-left-subtext">
                    We are a super small team trying to build forecast tools to help you surf more. We are a startup and sometimes there will be bugs in our tools, but we appreciate your feedback as it helps us build better features.
                  </p>
                  <br />
                  <p className="subs-left-subtext">

                    Surfable is subscriber-supported and relies on surfers like you. Your support is greatly appreciated!
                                  </p> <br></br>
                  <p className="subs-left-subtext">
                    *Contact us if you run into any payment issues{" "}
                    <a href="mailto:contact@surfable.co">
                      (contact@surfable.co)
                    </a>
                  </p>
                  <br />
                  
                </div>
                <div className="stripe-right-side">
                  {/*
                  <div className="mt-2">
                    { (this.state.planDetail) ? (<PaymentMethod subscription={this.state.planDetail}/>) : null }
                  </div>
                  */}
                  <div style={{ marginTop: "45px" }}>
                    <p className="subs-left-title"> Enter Payment Details</p>
                    {
                      this.state.isLoadingPayments ? (
                        <CircularProgress />
                      ) : this.state.paymentMethods.length > 0 
                        ? (
                          <FormControl>
                            <FormLabel>Select from your existing credit cards or add a new payment method</FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={this.state.selectedPaymentId}
                              onChange={(event) =>
                                this.cardChanged(event.target.value)
                              }
                            >
                              {this.state.paymentMethods.map( (method, idx) => (
                                <FormControlLabel key={method.id} value={method.id} control={<Radio />} label={`xxxx ` + method.card.last4 + ` (${method.card.brand})`} />
                              ))}
                              <FormControlLabel key="new" value="new" control={<Radio />} label="New credit card" />
                            </RadioGroup>
                          </FormControl>
                        )
                        : null
                    }
                    { (this.state.paymentMethods.length == 0 || this.state.selectedPaymentId == "new") 
                        ? (
                        <div>
                          <div>
                            <TextField 
                              label="Card Number" 
                              placeholder="xxxx xxxx xxxx xxxx"
                              type="text" 
                              inputProps={{maxLength:16}}
                              fullWidth="true"
                              value={this.state.cardNumber} 
                              onChange={(e) => this.setState({cardNumber:e.target.value}) }
                              onKeyPress={(e) => this.numericKeyPress(e)} />
                          </div>
                          <div style={{marginTop: "12px",display: "flex", flexDirection: "row", background:"transparent"}}>
                            <TextField 
                              label="Expiry Month" 
                              placeholder="MM"
                              type="text" 
                              inputProps={{
                                maxLength:2,
                              }}
                              value={this.state.cardMonth} 
                              onChange={(e) => this.setState({cardMonth:e.target.value}) }
                              onKeyPress={(e) => this.numericKeyPress(e)}
                              />
                            <div style={{width: "6px"}}></div>
                            <TextField 
                              label="Expiry Year" 
                              placeholder="YY"
                              type="text" 
                              inputProps={{
                                maxLength:2
                              }}
                              value={this.state.cardYear} 
                              onChange={(e) => this.setState({cardYear:e.target.value}) }
                              onKeyPress={(e) => this.numericKeyPress(e)}
                              />
                            <div style={{width: "6px"}}></div>
                            <TextField 
                              label="CVC" 
                              type="text" 
                              inputProps={{maxLength:2}}
                              value={this.state.cardCvc} 
                              onChange={(e) => this.setState({cardCvc:e.target.value}) }
                              onKeyPress={(e) => this.numericKeyPress(e)} />
                          </div>
                        </div>
                        ) : null
                    }
                    <Button 
                      variant="contained" 
                      className="mt-1 w-100"
                      onClick={() => this.createSubscription()}>Subscribe</Button>
                  </div>
                </div>

              </div>
            </div>
          </div>
          {
          this.state.isLoading ? (
            <div className="sweet-loading">
              <ClipLoader size={50} color={"#ffffff"} loading={true} />
            </div>
            ) : null
          }
        </div>
      </div>
    );
  }
}
