import { UserRow, VasatModel } from "vasat"

export class User extends UserRow {

    units
  
    constructor(v){
      super(v,'User')
    }
  
}

export class Region extends VasatModel {

  name
  abbreviation
  active

  constructor(v){
    super(v,'Region')
  }

}

export class Article extends VasatModel {

  name
  html
  active

  constructor(v){
    super(v,'Article')
  }

}

export class PlanStub extends VasatModel {

  planName;
  price;
  active;
  currency;
  interval;
  usageType;
  billingScheme;
  meta;

  constructor(v){
    super(v,'PlanStub');
  }

}