import { Component } from "react";
import { Helmet } from "react-helmet";
import Header from "../dashborad/Header";
import "./content.css";
import left_arrow from "../../assets/images/left-arrow.svg";
import AppService from "../../commons/AppService";

export default class Content extends Component {

    service = AppService.getInstance();

    constructor(props) {
        super(props);
    
        this.state = {
            article: {}
        };
    }

    componentDidMount() {
        var articleUid = this.props.match.params.article_uid;
        this.service.fetchArticle(articleUid).subscribe(res => {
            //console.log("ENTER Content article found: ",res);
            this.setState({
                article: res
            })
        });
    }

    goBack() {
        window.history.back();
    }

    createArticleBody() {
        return {__html: this.state.article.html ? this.state.article.html : "" };
    }
    
    render() {

        return(
            <div>
                <Helmet>
                    <title>{ this.state.article.name ? "" + this.state.article.name : "" } | Surfable Maps</title>
                </Helmet>
                <Header />
                <div className="page">
                    <div className="page-container">
                        <div className="back-section" onClick={() => this.goBack()}>
                            <img
                                src={left_arrow}
                                className="back-arrow"
                            />
                            <p className="back-text">
                            Go Back 
                            </p>
                        </div>
                        <div className="content-section">
                            <span className="page-title">{ this.state.article.name ? "" + this.state.article.name : "" }</span>
                            <div dangerouslySetInnerHTML={this.createArticleBody()} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}