
import React, { Component } from "react";
import logo from "../../assets/images/logo.png";
import "./sideBar.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import close from "../../assets/images/close.png";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import AppService from "../../commons/AppService";
toast.configure();

export default class SideBar extends Component {

  service = AppService.getInstance();

  constructor(props) {
    super(props);

    this.state = {
      token: null,
      isHomeActive: false,
      isPricingActive: false,
      isLoginActive: false,
      isRegisterActive: false,
      isSCActive: false,
      isMobileAppActive: false,
      isBackToSurferActive: false,
      isAllForecastsActive: false,
      isMySubscription: false,
      isAboutActive: false,
      isBlogActive: false,
      showSubscribe: false
    };
  }
  gotToDashboard = (history) => {
    history.push("/");
  };

  componentDidMount() {
    const token = this.service.getCurrentAccessToken();
    this.setState({
      token: token,
      isPricingActive:
        window.location.pathname == "/subscription" ? true : false,
      isHomeActive: window.location.pathname == "/" ? true : false,
      isLoginActive: window.location.pathname == "/login" ? true : false,
      isRegisterActive: window.location.pathname == "/register" ? true : false,
      isSCActive: window.location.pathname == "/swell_map/socal" ? true : false,
      isAboutActive: window.location.pathname == "/content/about" ? true : false,
      isMySubscription:
        window.location.pathname == "/my_subscriptions" ? true : false,
    });
  }
  onLogout = (history) => {
    this.service.logoutUser()
      .then((result) => {
        //console.log(result.status);

        toast.info("Logged Out Successfully", {
          autoClose: 2000,
        });
        // setTimeout( () => { this.props.history.push("/")},2000);
        localStorage.clear();
        history.push("/");
        this.setState({
          token: null,
        });
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          toast.error(`${error.response.data.data}`);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        this.setState({
          isLoading: false,
        });
      });
  };
  goToHome = (history) => {
    this.setState({
      isHomeActive: true,
      isPricingActive: false,
      isRegisterActive: false,
      isLoginActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: false,
      isAboutActive: false,
      isBlogActive: false,
      isMobileAppActive: false,
      isSCActive: false,
      isMySubscription: false,
    });
    history.push("/");
  };
  goToLogin = (history) => {
    this.setState({
      isHomeActive: true,
      isPricingActive: false,
      isRegisterActive: false,
      isLoginActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: false,
      isAboutActive: false,
      isBlogActive: false,
      isMobileAppActive: false,
      isSCActive: false,
      isMySubscription: false,
    });
    history.push("/login");
  };
  goToRegister = (history) => {
    this.setState({
      isHomeActive: false,
      isPricingActive: false,
      isRegisterActive: true,
      isLoginActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: false,
      isAboutActive: false,
      isBlogActive: false,
      isMobileAppActive: false,
      isSCActive: false,
      isMySubscription: false,
    });
    history.push("/register");
  };
  goToMySubscriptions = (history) => {
    this.setState({
      isHomeActive: false,
      isPricingActive: false,
      isRegisterActive: false,
      isLoginActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: false,
      isAboutActive: false,
      isBlogActive: false,
      isMobileAppActive: false,
      isSCActive: false,
      isMySubscription: true,
    });
    history.push("/my_subscriptions");
  };
  goToSouthernCalifornia = (history) => {
    this.setState({
      isHomeActive: false,
      isPricingActive: false,
      isRegisterActive: false,
      isLoginActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: false,
      isAboutActive: false,
      isBlogActive: false,
      isMobileAppActive: false,
      isSCActive: true,
      isMySubscription: false,
    });

    history.push("/swell_map/socal");
    window.location.reload();
  };

  goToPricing = (history) => {
    this.setState({
      isHomeActive: false,
      isPricingActive: true,
      isRegisterActive: false,
      isLoginActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: false,
      isAboutActive: false,
      isBlogActive: false,
      isMobileAppActive: false,
      isSCActive: false,
      isMySubscription: false,
    });
    history.push("/subscription");
  };
  goToAllForecats = (history) => {
    this.setState({
      isHomeActive: false,
      isPricingActive: false,
      isRegisterActive: false,
      isLoginActive: false,
      isAllForecastsActive: true,
      isBackToSurferActive: false,
      isAboutActive: false,
      isBlogActive: false,
      isMobileAppActive: false,
      isSCActive: false,
      isMySubscription: false,
    });
    window.open("https://forecast.surfer.com/explore/");
  };
  goToMobileApp = (history) => {
    this.setState({
      isHomeActive: false,
      isPricingActive: false,
      isRegisterActive: false,
      isLoginActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: false,
      isAboutActive: false,
      isBlogActive: false,
      isMobileAppActive: true,
      isSCActive: false,
      isMySubscription: false,
      isMySubscription: false,
    });
    window.open("https://forecast.surfer.com/mobile-app");
  };
  goToBackToSurfer = (history) => {
    this.setState({
      isHomeActive: false,
      isPricingActive: false,
      isRegisterActive: false,
      isLoginActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: true,
      isAboutActive: false,
      isBlogActive: false,
      isMobileAppActive: false,
      isSCActive: false,
      isMySubscription: false,
    });

    // window.open("https://www.surfer.com/",'_self')
    window.open("https://www.surfer.com/");
  };
  goToAbout = (history) => {
    this.setState({
      isHomeActive: false,
      isPricingActive: false,
      isRegisterActive: false,
      isLoginActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: false,
      isAboutActive: true,
      isBlogActive: false,
      isMobileAppActive: false,
      isSCActive: false,
      isMySubscription: false,
    });

    //window.open("https://forecast.surfer.com/about/", "_blank");
    history.push("/content/about");
  };

  goToBlog = (history) => {
    this.setState({
      isHomeActive: false,
      isPricingActive: false,
      isRegisterActive: false,
      isLoginActive: false,
      isAllForecastsActive: false,
      isBackToSurferActive: false,
      isAboutActive: false,
      isBlogActive: true,
      isMobileAppActive: false,
      isSCActive: false,
      isMySubscription: false,
    });

    window.open("https://forecast.surfer.com/category/weekly", "_blank");
  };

  goToMyProfile = (history) => {
    history.push("/profile");
  };

  goToMySubscription = (history) => {
    history.push("/my_subscriptions");
  };

  getSubscriptionPage = () => {
    this.setState({
      isLoading: true,
    });

    const url =
      "https://maps-surfer-api.surfable.co/subscription/customerportel";

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer" + " " + localStorage.getItem("token"),
      },
    };

    axios
      .post(url, {}, config)
      .then((res) => {
        console.log(res.data);
        window.location.href = res.data.session;
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {

        this.setState({
          isLoading: false,
          showSubscribe: true
        });
      });
  };
  hideSubscribePop() {
    this.setState({
      showSubscribe: false
    })
  }

  render() {
    return (
      <nav className="navbar navbar-inverse">
        <div className="container-fluid">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle"
              data-toggle="collapse"
              data-target="#myNavbar"
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <Route
              render={({ history }) => (
                <img
                  id="page-wrap"
                  src={logo}
                  className="headerLogo"
                  onClick={() => this.gotToDashboard(history)}
                />
              )}
            />
          </div>
          <div className="collapse navbar-collapse" id="myNavbar">
            <ul className="nav navbar-nav">
              {this.state.token == null ? (
                <Route
                  render={({ history }) => (
                    <li
                      className={this.state.isLoginActive ? "active" : null}
                      onClick={() => this.goToLogin(history)}
                    >
                      <a>Login</a>
                    </li>
                  )}
                />
              ) : null}
              {this.state.token == null ? (
                <Route
                  render={({ history }) => (
                    <li
                      className={this.state.isRegisterActive ? "active" : null}
                      onClick={() => history.push("/subscription")}
                    >
                      <a>Free Trial</a>
                    </li>
                  )}
                />
              ) : null}
              <Route
                render={({ history }) => (
                  <li
                    className={this.state.isHomeActive ? "active" : null}
                    onClick={() => this.goToHome(history)}
                  >
                    <a>Home</a>
                  </li>
                )}
              />
              {this.state.token != null ? (
                <>
                  <Route
                    render={({ history }) => (
                      <li
                        onClick={() => this.goToMyProfile(history)}
                      >
                        <a>Profile</a>
                      </li>
                    )}
                  />
                  <Route
                    render={({ history }) => (
                      <li
                        className={
                          this.state.isMySubscription ? "active" : null
                        }
                        onClick={() => this.goToMySubscription(history)}
                      >
                        <a>Membership</a>
                      </li>
                    )}
                  />
                </>
              ) : null}

              <Route
                render={({ history }) => (
                  <li
                    className={this.state.isSCActive ? "active" : null}
                    onClick={() => this.goToSouthernCalifornia(history)}
                  >
                    <a>Southern California</a>
                  </li>
                )}
              />
              <Route
                render={({ history }) => (
                  <li
                    className={this.state.isPricingActive ? "active" : null}
                    onClick={() => this.goToPricing(history)}
                  >
                    <a>Pricing</a>
                  </li>
                )}
              />
              {/*
              <Route
                render={({ history }) => (
                  <li
                    className={
                      this.state.isAllForecastsActive ? "active" : null
                    }
                    onClick={() => this.goToAllForecats(history)}
                  >
                    <a>Explore</a>
                  </li>
                )}
              />
              
              <Route
                render={({ history }) => (
                  <li
                    className={this.state.isMobileAppActive ? "active" : null}
                    onClick={() => this.goToMobileApp(history)}
                  >
                    <a>Mobile App</a>
                  </li>
                )}
              />
              */}
              <Route
                render={({ history }) => (
                  <li
                    className={
                      this.state.isBackToSurferActive ? "active" : null
                    }
                    onClick={() => this.goToBackToSurfer(history)}
                  >
                    <a>Back To Surfer</a>
                  </li>
                )}
              />
              <Route
                render={({ history }) => (
                  <li
                    className={this.state.isAboutActive ? "active" : null}
                    onClick={() => this.goToAbout(history)}
                  >
                    <a>About</a>
                  </li>
                )}
              />
              <Route
                render={({ history }) => (
                  <li
                    className={this.state.isBlogActive ? "active" : null}
                    onClick={() => this.goToBlog(history)}
                  >
                    <a>Blog</a>
                  </li>
                )}
              />
              {this.state.token != null ? (
                <Route
                  render={({ history }) => (
                    <li onClick={() => this.onLogout(history)}>
                      <a>Logout</a>
                    </li>
                  )}
                />
              ) : null}
            </ul>
          </div>
        </div>
        {this.state.showSubscribe ? <div className="popup-container" onClick={() => this.hideSubscribePop()}>
          <div className="popup-outer" onClick={(e) => e.stopPropagation()}>
            <img src={close} className="close-icon" onClick={() => this.hideSubscribePop()} />
            <h2>Subscribe Now!</h2>
            <p>7 day surf wave height & period maps for Southern California, Northern California and Hawaii</p>
            <button className="subscribe-btn" onClick={() => window.location.href = '/subscription'}>Subscribe</button>
          </div>
        </div> : null}
      </nav>
    );
  }
}