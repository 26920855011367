import React, { Component } from "react";
import "./SignIn.css";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import { ReactComponent as EmailSvg } from "../../assets/images/email.svg";
import { ReactComponent as KeySvg } from "../../assets/images/key.svg";
import { ReactComponent as UserSvg } from "../../assets/images/user.svg";
import logo from "../../assets/images/logo.png";
import qs from "qs";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsArrowLeft } from "react-icons/bs";
import Header from '../dashborad/Header';
import {Helmet} from "react-helmet";
import AppService from "../../commons/AppService";
import { firstValueFrom } from "rxjs";

toast.configure();
export default class SignUp extends Component {

  service = AppService.getInstance()

  constructor(props) {
    super(props);

    this.state = {
      validEmail: false,
      validName: false,
      validPassword: false,
      emailErrorMsg: "",
      passwordErrorMsg: "",
      nameErrorMsg: "",
      email: "",
      name: "",
      password: "",
      emailError: false,
      passwordError: false,
      nameError: false,
      errors: {},
      errorMsgText: "",
      errorMsg: false,
      isLoading: false,
      isPasswordShow: false,
      focus: false,
      passwordFocus: false,
      nameFocus: false,
      captcha:'',
      captchaKey:this.service.captchaKey
    };
    this._onFocus = this._onFocus.bind(this);
    this._onFocus2 = this._onFocus2.bind(this);
    this._onFocus3 = this._onFocus3.bind(this);

    console.log('start ' + this.service.captchaKey,this.state);

    setTimeout(() => {
      console.log(this.service.captchaKey)
      this.state.captchaKey = this.service.captchaKey
      this.forceUpdate()
    },1500
    )
  }

  _onFocus() {
    if (!this.state.focus) {
      this.setState({
        focus: true,
        passwordFocus: false,
        nameFocus: false,
      });
    }
  }
  _onFocus2() {
    if (!this.state.passwordFocus) {
      this.setState({
        focus: false,
        passwordFocus: true,
        nameFocus: false,
      });
    }
  }
  _onFocus3() {
    if (!this.state.nameFocus) {
      this.setState({
        focus: false,
        passwordFocus: false,
        nameFocus: true,
      });
    }
  }
  togglePasswordVisiblity = () => {
    const { isPasswordShow } = this.state;
    this.setState({
      isPasswordShow: !isPasswordShow,
    });
  };
  validateEmail = (e) => {
    let emailRegExp = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/;
    const email = e.target.value;

    if (email == "") {
      this.setState({
        validEmail: true,
        emailErrorMsg: "Email is required",
      });
    } else if (!email.match(emailRegExp)) {
      this.setState({
        validEmail: true,
        emailErrorMsg: "Please enter the valid email",
      });
    } else {
      this.setState({
        validEmail: false,
        emailErrorMsg: "",
        focus: false,
      });
    }
  };
  validatePassword = (e) => {
    const password = e.target.value;
    let passwordRegEx = "^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$";
    if (password == "") {
      this.setState({
        validPassword: true,
        passwordErrorMsg: "Password is required",
      });
    }
    //  else if (!password.match(passwordRegEx)) {
    //   this.setState({
    //     validPassword: true,
    //     passwordErrorMsg:
    //       "Password must be atleast 8 characters long one uppercase with one lowercase, one special character & one numeric character",
    //   });
    // }
    else if (password.length < 8 ) {
      this.setState({
        validPassword: true,
        passwordErrorMsg:
          "Password must be atleast 8 characters long",
      });
    }  
    else {
      this.setState({
        validPassword: false,
        passwordErrorMsg: "",
        passwordFocus: false,
      });
    }
  };
  validateName = (e) => {
    const name = e.target.value;

    if (name == "") {
      this.setState({
        validName: true,
        nameErrorMsg: "Name is required",
      });
    } else {
      this.setState({
        validName: false,
        nameErrorMsg: "",
        nameFocus: false,
      });
    }
  };
  componentDidMount() {
    // this.refs.name.focus();
  }

  handleKeyPress(value, event) {
    if (event.key === "Enter") {
      this.refs[value].focus();
    }
  }

  handleValidation() {
    const { email, password, name } = this.state;
    let errors = {};
    let formIsValid = true;
    let emailRegExp = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/;
    let passwordRegEx = "^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$";

    //name
    if (!name) {
      formIsValid = false;
      errors["name"] = "Name is required";
      this.setState({
        nameError: true,
      });
    }

    //email
    if (this.state.email == "") {
      formIsValid = false;
      errors["email"] = "Email is required";
      this.setState({
        emailError: true,
      });
    } else if (typeof email !== "undefined") {
      if (!email.match(emailRegExp)) {
        formIsValid = false;
        errors["email"] = "Invalid email address";
        this.setState({
          emailError: true,
        });
      }
    }

    //password
    if (!password) {
      formIsValid = false;
      errors["password"] = "Password is required";
      this.setState({
        passwordError: true,
      });
    } 
    else if (typeof password !== "undefined") {
      if (password.length < 8 ) {
        formIsValid = false;
        errors["password"] =
          "Password must be atleast 8 characters long";
        this.setState({
          passwordError: true,
        });
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  resisterUser = () => {
    if (this.handleValidation()) {
      this.setState({
        isLoading: true,
      });
  
      firstValueFrom(this.service.registerNewUser(this.state.name, this.state.email, this.state.password,this.state.captcha))
        .then(res => {
          this.setState({
            isLoading: false,
          });
          toast.success("Registration Successful!", {autoClose: 1000});
          this.props.history.push("/");
        }).catch(error => {
          this.setState({
            isLoading: false,
          });
          toast.error("Oops Something Went Wrong" + ((error.message) ? error.message : error) );
        });
    }
  };

  render() {
    const { errors, validEmail, validPassword, validName } = this.state;
    const { isPasswordShow } = this.state;
    return (
      <>
       <Helmet>
          <title>Register | Surfable Maps</title>
          <meta name="description" content=" Register for a Surfable Account"></meta>
        
        </Helmet>
        <Header />
        <div className="imageBackground">
          {/* <div className="overlay"></div> */}
          <div className="container">
            <div className="main">
              <div className="left-side">
                <img
                  src={logo}
                  className={"logoImg"}
                  style={{ marginBottom: "4px" }}
                />

                <p className="left-side-text">
                  <b className="lgntxt">10 day surf wave height & period maps for Southern California, Northern California and Hawaii</b>
                </p>
                {/* <p className="left-side-text">
                  Create an account so you can see the 7 day premium forecasts
              </p> */}
                <div
                  className="left-arrow-back-to-home"
                  onClick={() => this.props.history.push("/")}
                >
                  <BsArrowLeft
                    size="30px"
                    style={{ position: "relative", top: "-6px" }}
                  />
                  <span style={{ marginLeft: "5px", color: "#1976D2" }}>
                    Back To Home
                </span>
                </div>
              </div>

              <div className="right-side">
                <p className="login-text"> Register </p>
                <p className="sub-text">
                  Create an account so you can see the 10 day premium forecasts
              </p>
                {/* <div style={{ maxWidth: "600px" }}> */}
                <div
                  className="input-container"
                  style={{
                    marginBottom:
                      this.state.validName || this.state.nameError ? 0 : "5%",
                    border:
                      this.state.validName || this.state.nameError
                        ? "1px solid red"
                        : this.state.nameFocus
                          ? "3px solid #2680EB0F"
                          : "1px solid #CDDEF3",
                  }}
                >
                  <UserSvg className="icons" size="10px" />
                  <input
                    type="text"
                    placeholder="Name"
                    ref="name"
                    onBlur={this.validateName}
                    onFocus={this._onFocus3}
                    onChange={(e) =>
                      this.setState({
                        name: e.target.value,
                        nameError: false,
                        errorMsg: false,
                        validName: false,
                        nameErrorMsg: "",
                      })
                    }
                    value={this.state.name}
                    onKeyPress={(event) => this.handleKeyPress("email", event)}
                  />
                </div>
                {validName || this.state.nameError ? (
                  <p style={{ marginBottom: "5%" }} className="error-Text">
                    {this.state.nameErrorMsg || errors["name"]}
                  </p>
                ) : (
                    ""
                  )}

                <div
                  className="input-container"
                  style={{
                    marginBottom:
                      this.state.validEmail || this.state.emailError ? 0 : "5%",
                    border:
                      this.state.validEmail || this.state.emailError
                        ? "1px solid red"
                        : this.state.focus
                          ? "3px solid #2680EB0F"
                          : "1px solid #CDDEF3",
                  }}
                >
                  <EmailSvg className="icons" />
                  <input
                    type="email"
                    placeholder="Email Address"
                    ref="email"
                    onBlur={this.validateEmail}
                    onFocus={this._onFocus}
                    onChange={(e) =>
                      this.setState({
                        email: e.target.value,
                        emailError: false,
                        errorMsg: false,
                        validEmail: false,
                        emailErrorMsg: "",
                      })
                    }
                    value={this.state.email}
                    onKeyPress={(event) => this.handleKeyPress("password", event)}
                  />
                </div>
                {validEmail || this.state.emailError ? (
                  <p style={{ marginBottom: "5%" }} className="error-Text">
                    {this.state.emailErrorMsg || errors["email"]}
                  </p>
                ) : (
                    ""
                  )}
                <div
                  className="input-container"
                  style={{
                    marginBottom:
                      this.state.validPassword || this.state.passwordError
                        ? 0
                        : "8%",
                    border:
                      this.state.validPassword || this.state.passwordError
                        ? "1px solid red"
                        : this.state.passwordFocus
                          ? "3px solid #2680EB0F"
                          : "1px solid #CDDEF3",
                  }}
                >
                  <KeySvg className="icons" />
                  <input
                    placeholder="Password"
                    type={isPasswordShow ? "text" : "password"}
                    ref="password"
                    onChange={(e) =>
                      this.setState({
                        password: e.target.value,
                        passwordError: false,
                        validPassword: false,
                        passwordErrorMsg: "",
                      })
                    }
                    onFocus={this._onFocus2}
                    onBlur={this.validatePassword}
                    value={this.state.password}
                  />
                  {!isPasswordShow ? (
                    <IoIosEye
                      className="eye-icon"
                      size="25px"
                      color="#aaa"
                      onClick={this.togglePasswordVisiblity}
                    />
                  ) : (
                      <IoIosEyeOff
                        className="eye-icon"
                        size="25px"
                        color="#aaa"
                        onClick={this.togglePasswordVisiblity}
                      />
                    )}
                </div>
                {this.state.validPassword || this.state.passwordError ? (
                  <p style={{ marginBottom: "8%" }} className="error-Text">
                    {errors["password"] || this.state.passwordErrorMsg}
                  </p>
                ) : null}

     

                <img src={this.service.vasat.config.host + '/' + this.state.captchaKey + '/captcha.png'} class="captcha" />
                <div
                  className="input-container"  style={{
                    marginBottom:
                       "8%",
                    border:
                      
                         "1px solid #CDDEF3",
                  }}>

<KeySvg className="key" />
                  <input
                    placeholder="Enter text shown above"
                    type="text"
                    ref="captcha"
                    onChange={(e) =>
                      this.setState({
                        captcha: e.target.value
                      })
                    }
                    value={this.state.captcha}
                  />
</div>
                <button
                  type="submit"
                  className="btn"
                  onClick={this.resisterUser}
                // disabled={
                //   this.state.email === "" || this.state.password === ""
                //     ? true
                //     : false
                // }
                >
                  Register
              </button>

                <p className="footerText">
                  Already have an account?{" "}
                  <span
                    onClick={() => {
                      this.props.history.push("/login");
                    }}
                    style={{
                      color: "#37478594",
                      cursor: "pointer",
                      fontSize: "1em",
                      fontFamily: "Source-SemiBold",
                    }}
                  >
                    Login Here!
                </span>
                </p>
                <div
                  className="footer-left-arrow"
                  onClick={() => this.props.history.push("/")}
                >
                  <BsArrowLeft className="login-left-arrow-icon" />
                  <span style={{ marginLeft: "5px", color: "#1976D2" }}>
                    Back To Home
                </span>
                </div>
              </div>
            </div>
          </div>
          {this.state.isLoading ? (
            <div className="sweet-loading">
              <ClipLoader size={50} color={"#ffffff"} loading={true} />
            </div>
          ) : null}
        </div>
      </>
    );
  }
}
