import React, { Component } from "react";
import Header from "./Header";
import "./MySubscriptionPage.css";
import { FaFileInvoiceDollar,FaToolbox,FaExternalLinkAlt,FaCheckCircle,FaPercent } from "react-icons/fa";
import { BsArrowLeft } from "react-icons/bs";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import AppService from "../../commons/AppService";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PaymentMethod from  "../dashborad/stripe/PaymentMethod"
import { debounceTime, firstValueFrom, map, Subject } from "rxjs";
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

export default class MySubscriptionPage extends Component {

  service = AppService.getInstance();
  promoChangeHandler = new Subject();
  
  constructor(props) {
    super(props);

    this.state = {
      planDetails: [],
      invoices:[],
      selectedPrice:null,
      selectedPaymentId:null,
      subscription: null,
      isLoading: false,
      firstTimeLoad:false,
      isDialogOpen: false,
      dialogOption:"",
      couponError:null,
      couponId:null,
      promoCode:""
    };
  }
  componentDidMount() {
    this.initData();
    this.promoChangeHandler.asObservable()
      .pipe(
        // The result is an HTML onChange event
        map(event =>  (event && event.target) ? event.target.value : ""),
        debounceTime(800),
      )
      .subscribe(val => this.promoCodeSearch(val) );
  }

  promoCodeSearch(code) {
    if (code) {
      this.service.getPromotionCodes(code).subscribe(res => {
        this.setState({
          couponId: (res && res.length > 0) ? res[0].couponId : null,
          promoCode:code,
          couponError: (res && res.length > 0) ? null : "Promo code invalid or maybe expired"
        });
      });
    } else {
      this.setState({promoCode:code,couponError:null,couponId:null});
    }
  }

  handleClose(positive) {
    this.setState({
      isDialogOpen: false,
    });
    // If positive, in this case unsubscribe the user
    if (positive) {
      // Check dialog option
      if (this.state.dialogOption == 'switch' || this.state.dialogOption == 'subscribe') {
        this.createOrUpgradeSubscription(this.state.selectedPrice.id)
      } else if (this.state.dialogOption == 'cancel') {
        this.cancelSubscription();
      }
    }
  };

  cancelSubscription() {
    this.setState({
      isLoading: true,
    });

    this.service.cancelSubscription()
      .then( res => toast.success("Your membership has been cancelled.") )
      .catch( err => toast.error("There was an error. " + ((err.message) ? err.message : err ) ))
      .finally( () => {
        // Refresh subscription
        this.initData()
      })
  };

  initData = () => {
    this.setState({
      isLoading: true,
    });

    this.service.getPublicPlans(false)
      .then(resPlans => {
        this.setState({
          planDetails: resPlans,
        });
        return firstValueFrom(this.service.getInvoices()) ;
      })
      .then(resInv => {
        this.setState({
          invoices: resInv.results
        })
        return this.service.getSubscription();
      })
      .then((res) => {
        //console.log("ENTER getSubscription res",res)
        this.setState({
          isLoading: false,
          firstTimeLoad: true,
          subscription: res
        });
      })
      .catch((error) => {
        //console.log("ENTER getSubscription error",error)
        this.setState({
          isLoading: false,
          firstTimeLoad: true,
          subscription: null,
        });
        if (error && error.message) {
          toast.error(error.message, {
            autoClose: 2000,
          });
        }
      });      
  };

  onPaymentChanged = (paymentId) => {
    //console.log("ENTER onPaymentChanged",paymentId);
    this.setState({selectedPaymentId:paymentId});
  };

  /** Note that when creating subscription on backend it will automatically cancel any existing subscriptions.
   * Therefore no matter if we are creating one for the first time or we are upgrading we call the same endpoint
   */
  createOrUpgradeSubscription(selectedPrice) {
    if (this.state.selectedPaymentId && this.state.selectedPaymentId != "new") {
      this.setState({
        isLoading: true
      });
      
      this.service.createSubscription(selectedPrice, this.state.selectedPaymentId, false, "USD", this.state.couponId)
        .subscribe(
          succ => {
            this.setState({
              isLoading: false,
            });
            toast.success("Subscription Successful!", {autoClose: 1000});
            this.initData();
          }, error => {
            this.setState({
              isLoading: false,
            });
            toast.error("Oops Something Went Wrong" + ((error.message) ? error.message : error) );
          }
        )
    } else {
      toast.warning("Please select or add a payment method", {autoClose: 2000});
    }
  }

  onPaymentMethodUpdate() {
    if (this.state.selectedPaymentId && this.state.selectedPaymentId != "new") {
      this.setState({
        isLoading: true
      });
      this.service.updateSubscriptionPayment(this.state.subscription.id, this.state.selectedPaymentId)
        .subscribe(
          res => {
            this.setState({
              isLoading: false
            });
            toast.success("Your payment method has been updated.")
          }, error => {
            this.setState({
              isLoading: false
            });
            if (error && error.message) {
              toast.error(error.message, {
                autoClose: 2000,
              });
            }
          }
        )
    } else {
      toast.warning("Please select a payment method", {autoClose: 2000});
    }
  }
  
  render() {
    return (
      <div>
        <Header />
        <div className="my-subscription-header">
          <div className="my-subs-heading-align">
            <div className="back-to-text" style={{ marginRight: "20px" }}>
              {" "}
              <BsArrowLeft
                onClick={() => this.props.history.push("/")}
                // size="30px"
                style={{
                  position: "relative",
                  fontSize: "30px",
                  top: "10px",
                  cursor: "pointer",
                }}
              />
              Back to Home
            </div>
            <p className="my-subs-heading"> Membership</p>
          </div>
        </div>
        <div className="card-align">
          <div className="my-subscription-card">
            { (!this.state.firstTimeLoad) ? null : (
            <div className="flex-row flex-center">
              <div className="p-2" style={{flex:'40'}}>
                <div className="flex-row">
                  <FaToolbox
                    color=" #1976D2"
                    className="subscriptions-icon"
                  />
                  <div className="f-m f-bold"> Plan Details</div>
                </div>
                <hr/>
                {(this.state.subscription) ? null : (
                  <p className="f-l mt-1 mb-1">
                    You have no subscriptions. Subscribe to view 10-day forecast
                  </p>
                )}

                <div className="flex-row">
                  { this.state.planDetails.map((data, idx) => (
                    <div 
                    style={{flex:'1', marginLeft:'8px', marginRight:'8px'}}
                    className="cards-wrap pointer" 
                    id={`sub${idx}`} 
                    key={`sub${idx}`}
                    >
                    <div className={`price-card pt-2 ${ (this.state.subscription && this.state.subscription.price.id == data.id) ? 'selected-card' : 'normal-card'}`}>
                      <span className="f-m f-bold mb-1" style={{ textTransform: "capitalize" }}>{(this.state.subscription && this.state.subscription.price.id == data.id && this.state.subscription.status == "trialing") ? "30 Day Free Trial" : "Premium " + data.interval + "ly"}</span>
                      
                      <p className="subs-plan-title">
                        $ {data.price}
                        <span
                          style={{
                            fontSize: "0.9em",
                            fontFamily: "Source-REg",
                            textAlign: "center",
                            color: "",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          / Per {data.interval}
                        </span>
                      </p>

                      {(this.state.subscription && this.state.subscription.price.id == data.id) ? (
                        <div className="pb-1 flex-column flex-align-center mt-1">
                          <span className="f-bold f-m">(Current Plan)</span>
                          <span className="f-s">Plan is {this.state.subscription.status}</span>
                          <span className="f-s">Started {moment(this.state.subscription.created*1000).format("DD MMM YYYY")}</span>
                          <span className="f-s">{(this.state.subscription.status == "trialing") ? 'Trial ends' : 'Next invoice'} {moment(this.state.subscription.currentPeriodEnd*1000).format("DD MMM YYYY")}</span>
                          <Button variant="outlined" className="mt-1" onClick={(e) => this.setState({ isDialogOpen: true, dialogOption:'cancel' }) }>Cancel Plan</Button>
                        </div>
                      ) : ( (this.state.subscription) ? (
                        <div className="pb-1 flex-column flex-align-center">
                          <div className="mt-2 mb-1 f-bold f-m" style={{textAlign:'center'}}>{(data.interval == 'year') ? 'Upgrade and save $5.89' : ''}</div>
                          <Button variant="contained" className="mt-1" onClick={(e) => this.setState({ isDialogOpen: true, dialogOption:'switch', selectedPrice:data }) } style={{backgroundColor:'#5cdb5c'}}>Switch Plan</Button>
                        </div>
                      ) : (
                        <Button variant="contained" className="mt-1 mb-1" onClick={(e) => this.setState({ isDialogOpen: true, dialogOption:'subscribe', selectedPrice:data }) } style={{backgroundColor:'#5cdb5c'}}>Subscribe</Button>
                      ))}
                    </div>
                  </div>
                  ) ) }
                </div>

              </div>
              <div className="p-2" style={{flex:'40'}}>
                <PaymentMethod subscription={this.state.subscription} OnPaymentChange={this.onPaymentChanged}/>
                {(this.state.subscription && this.state.selectedPaymentId != 'new' && this.state.subscription.defaultPaymentMethod != this.state.selectedPaymentId) ? (
                  <Button variant="contained" className="mt-1" onClick={() => this.onPaymentMethodUpdate()}>Update payment method</Button>
                ) : null}
              </div>
              <div className="p-2" style={{flex:'20'}}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <FaFileInvoiceDollar
                    color=" #1976D2"
                    className="subscriptions-icon"
                  />
                  <div className="f-m f-bold"> Invoice History</div>
                </div>
                <hr/>
                {this.state.invoices.map(inv => (
                  <div className="flex-row" key={inv.id}>
                    <span className="ml-1 f-bold">{moment(inv.created*1000).format("DD MMM YYYY")}</span>
                    <span className="ml-1">{inv.currency.toUpperCase()}${inv.amountPaid}</span>
                    <a href={inv.hostedInvoiceUrl} target="_blank"><FaExternalLinkAlt color=" #1976D2" className="subscriptions-icon ml-1"/></a>
                    
                  </div>
                ))}
              </div>
            </div>
            ) }
          </div>
        </div>
        <Dialog
          open={this.state.isDialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            { (this.state.dialogOption == 'cancel') ? "Unsubscribe:" : ( (this.state.dialogOption == 'switch') ? "Change plan" : "Subscribe") }
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {(this.state.dialogOption == 'cancel') 
                ? "Are you sure you would like to unsubscribe?."
                : (this.state.dialogOption == 'switch') 
                  ? "Are you sure you would like to change plans? This will update your current subscription." 
                  : `You are subscribing to $${ (this.state.selectedPrice) ? this.state.selectedPrice.price : ''} / Per ${(this.state.selectedPrice) ? this.state.selectedPrice.interval: ''}. If you have a promo code, please enter it below.`}
              
            </DialogContentText>
            {(this.state.dialogOption == 'switch' || this.state.dialogOption == 'subscribe') ? (
              <TextField 
                label="Enter Promo Code" 
                placeholder="Promo code"
                className="mt-2 w-100"
                type="text" 
                InputProps={{
                  startAdornment: <InputAdornment position="start"><FaPercent /></InputAdornment>,
                  endAdornment: (this.state.couponId) ? (<InputAdornment position="start"><FaCheckCircle color="#4CAF50" size="30px"/></InputAdornment>) : null
                }}
                onChange={(event) => this.promoChangeHandler.next(event)}
                helperText={ (this.state.couponError) ? this.state.couponError : ""}
              />
            ) : null }

          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose(true)}>Ok</Button>
            <Button onClick={() => this.handleClose(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
        {this.state.isLoading ? (
          <div className="sweet-loading">
            <ClipLoader size={50} color={"#ffffff"} loading={true} />
          </div>
        ) : null}
      </div>
    );
  }
}
