import React, { Component } from "react";
import "./SwellMap.css";
import legend_hs_ft from "../../../assets/images/legend_hs_ft.png";
import legend_tp_sec from "../../../assets/images/legend_tp_sec.png";
import close from "../../../assets/images/close.png";
import mapMaker from "../../../assets/images/mappin.png";
import Header from "../Header";
import axios from "axios";
import L from "mapbox.js";
import 'leaflet.markercluster';
import $ from "jquery";
import moment from "moment-timezone";
import left_arrow from "../../../assets/images/left-arrow.svg";
import down_svg from "../../../assets/images/down.svg";
import play_svg from "../../../assets/images/play.svg";
import { BiWind } from "react-icons/bi";
import Slider from "rc-slider";
import { TimelineMax } from "gsap/all";
import "rc-slider/assets/index.css";
import { AiFillPlaySquare, AiFillPauseCircle, AiFillFastForward, AiFillFastBackward } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsPlayFill } from "react-icons/bs";
import { AiOutlinePause, AiFillPlayCircle } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";
import { Helmet } from "react-helmet";
import { spotList } from "./spots.js";
import AppService from "../../../commons/AppService";
import CircularProgress from '@mui/material/CircularProgress';
import NoCal from "./nocal.json";
import SoCal from "./socal.json";
import Nepac from "./nepac.json";

import Baird from './swellmap_v2'

const v2Maps = ['nocal','socal','nepac']
const mapToken =
  "pk.eyJ1IjoiZGl0dG9wcyIsImEiOiJjazhuNDJlMWkwaThuM25uc2JoYmxtcW8wIn0.2063I606c-KsbhBfO1KV_A";
const S3_BUCKET = "https://swellmaps.s3-us-west-1.amazonaws.com";
let currentFolder = "";
let configurations = {};
let mapType = "height";
let mapObj;
let imageLayer;
let directionLayer;
let spotLayer;
let animationInterval;
let showDirectionLayer = true;
let isLegacyMap = true;
let mapLocation = "";
let resolution = {
  current: -1,
  list: ["512", "1024", "2048"],
  levels: [6, 8, 10],
  currentZoom: 8,
  maxZoom: 21,
  spec: {},
};
let frameCount = 0;
let firstzoom = true;
let levelCache = {};
let newMapJsonConfig = {};
let cycleVarName = 'cycle';

function pad(n, l) {
  var s = "" + n;
  while (s.length < l) s = "0" + s;
  return s;
}
function worldfile_extents(dx, dy, ox, oy, nx, ny) {
  var ex = ox + dx * (nx - 1);
  var ey = oy + dy * (ny - 1);
  return dy < 0.0 ? [ox, ey, ex, oy] : [ox, oy, ex, ey];
}
function generateImageUrl(index, type = "") {
  let layerType;
  type ? (layerType = type) : (layerType = mapType);
  return (
    S3_BUCKET +
    "/" +
    currentFolder +
    "/" +
    mapLocation +
    "/" +
    resolution.list[resolution.current] +
    "/" +
    layerType +
    "/" +
    mapLocation +
    "_" +
    layerType +
    "_" +
    resolution.list[resolution.current] +
    "_" +
    pad(index, 6) +
    ".png"
  );
}

toast.configure();

let thisRef = null;

export default class SwellMap extends Component {
  dropdownLocationMenu = React.createRef();
  service = AppService.getInstance();

  constructor(props) {
    super(props);
    thisRef = this;
    this.state = {
      region:{},
      regions:[],
      currentFolder: "",
      value1: true,
      spotCheck: true,
      currentFolder: "",
      mapType: "height",
      showDirectionLayer: true,
      mapLocation: "socal",
      resolution: {
        current: -1,
        list: ["512", "1024", "2048"],
        levels: [6, 8, 10],
        spec: {},
        frameCount: 0,

      },
      frameCount: 0,
      mapObj: "",
      imageLayer: "",
      directionLayer: "",
      animationInterval: "",
      lng: 5,
      lat: 34,
      zoom: 15,
      username: "",

      sliderMax: 0,
      isSubscribed: false,
      token: "",
      isLoading: false,
      showSubscribe: false,

      currentSpot: {},
      swellHeight: [],
      preloadTotal:0,
      preloadCount:0,
      isCurrentLevelPreloaded:false
    };
    this.tl = null;
    this.addImage = this.addImage.bind(this);
  }


  componentDidMount() {
    //console.log("ENTER SwellMap ",this.props);
    mapLocation = this.props.match.params.location_name;
    isLegacyMap = v2Maps.indexOf(mapLocation) == -1
    newMapJsonConfig = this.getNewMapsConfig(mapLocation);
    cycleVarName = isLegacyMap ? 'cycle' : 'ot'
    // Load Regions and slected region object from local store based on the route location_name
    this.service.getRegions().subscribe(res => {
      this.setState({
        regions: res,
        region: res.find(ele => ele.id == mapLocation)
      });
    });

    if (mapLocation) {
      this.callApiIsUserSubscribed();
    }
    document.addEventListener("mousedown", this.handleClickOutside);
    var theToken = this.service.getCurrentAccessToken()
    //console.log("ENTER SwellMap theToken",theToken)
    this.setState({
      token: theToken,
    });
    this.getSpots();
  }

  getNewMapsConfig(locationName) {
    if (locationName == 'nocal') {
      return NoCal;
    } else if (locationName == 'socal') {
      return SoCal;
    } else if (locationName == 'nepac') {
      return Nepac;
    } else {
      return {};
    }
  }

  callApiIsUserSubscribed = () => {

    var userToken = this.service.getCurrentAccessToken()

    if (userToken) {
      this.setState({
        isLoading: true,
        isSubscribed: false
      });
  
      this.service.getSubscription()
        .then((res) => {
          //console.log("ENTER SWELL getSubscription success",res);
          var tmpIsSubscribed = (res != null);
          
          this.setState({
            isLoading: false,
            isSubscribed: tmpIsSubscribed
          });
          this.getLatestConfig();
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            isSubscribed: false
          });
          if (error && error.message && error.status != 40021) {
            toast.error(error.message, {
              autoClose: 2000,
            });
          }
        });
    } else {
      // If no token then not subscribed. But still setup the Maps
      this.setState({
        isSubscribed: false
      });
      this.getLatestConfig();
    }
  };
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.dropdownLocationMenu.current &&
      !this.dropdownLocationMenu.current.contains(event.target)
    ) {
      this.setState({
        open: false,
      });
    }
  };
  handleButtonClick = () => {
    this.setState((state) => {
      return {
        open: !state.open,
      };
    });
  };

  toggleDirection = () => {

    this.setState({
      value1: !this.state.value1,
    });

    showDirectionLayer = !this.state.value1;
    if(isLegacyMap){
    
      if (!showDirectionLayer) {

        mapObj.removeLayer(directionLayer);

      } else {
        this.getConfig(resolution.current);

      }
    }
    else{
      Baird.showVector(showDirectionLayer)
    }
  };

  toggleSpots = () => {

    this.setState({
      spotCheck: !this.state.spotCheck,

    });
    if (this.state.spotCheck) {
      mapObj.removeLayer(spotLayer);

    } else {
      this.generateSpots();
    }
  };

  changeMapType = (type) => {

    $(".legend-wrap").removeClass(mapType);
    mapType = type;
    $(".legend-wrap").addClass(mapType);
    $("#map-type .type").removeClass("active");
    $('.type[data-type="' + mapType + '"]').addClass("active");
    if(isLegacyMap) {
      this.getConfig(resolution.current);
    } else {
      Baird.setSurface(type)
    }

  };

  playControl(e) {

    if (!this.isCurrentLevelLoadedWraper()) {
      this.preloadImagesWraper();
    } else {
      let btn = $(e.target).closest('.play-btn');
      this.clearControl();
      if ($(btn).hasClass("active")) {
        $(btn).removeClass("active");
      } else {
        $('.play-btn').removeClass("active");
        $(btn).addClass("active");
        this.playAnimation();
        //console.log(e);
      }
    }
  }

  playAnimation = () => {
    if ((this.state.isSubscribed == false || this.state.token == null) && (this.state.frameCount >= this.state.sliderMax - 1)) {
      this.setState({
        showSubscribe: true,
        frameCount: 0
      });
      $('.play-btn').removeClass("active");
      return

    }
    this.addImage((this.state.frameCount + 1) % resolution.spec.nt);
    // $("#slider-wrap").slider({ value: frameCount });
    this.setState({
      sliderValue: this.state.frameCount,

    })
    animationInterval = setTimeout(this.playAnimation, 500);

  };

  clearControl() {
    clearTimeout(animationInterval);
    // $('.play-btn').removeClass("active");
  }

  fastForwardControl(e) {
    if (!this.isCurrentLevelLoadedWraper()) {
      this.preloadImagesWraper();
    } else {
      //console.log("fast-forward");
      let btn = $(e.target).closest('.play-btn');
      this.clearControl();
      if ($(btn).hasClass("active")) {
        $(btn).removeClass("active");

      } else {
        $('.play-btn').removeClass("active");
        $(btn).addClass("active");
        this.fastForwardAnimation();
      }
    }
  }

  fastForwardAnimation = () => {
    if ((this.state.isSubscribed == false || this.state.token == null) && (this.state.frameCount >= 32)) {
      this.setState({
        showSubscribe: true,
        frameCount: 0
      });
      $('.play-btn').removeClass("active");
      return

    }
    //console.log(this.state.frameCount);
    this.addImage((this.state.frameCount + 3) % resolution.spec.nt);
    // $("#slider-wrap").slider({ value: frameCount });
    this.setState({
      sliderValue: this.state.frameCount,
    });
    animationInterval = setTimeout(this.fastForwardAnimation, 300);

  };

  reverseControl(e) {
    if (!this.isCurrentLevelLoadedWraper()) {
      this.preloadImagesWraper();
    } else {
      let btn = $(e.target).closest('.play-btn');
      this.clearControl();
      if ($(btn).hasClass("active")) {
        $(btn).removeClass("active");
      } else {
        $('.play-btn').removeClass("active");
        $(btn).addClass("active");
        this.reverseAnimation();
      }
    }
  }

  reverseAnimation = () => {
    if ((this.state.isSubscribed == false || this.state.token == null) && (this.state.frameCount >= 32)) {
      this.setState({
        showSubscribe: true,
        frameCount: 0
      });
      $('.play-btn').removeClass("active");
      return;
    }
    this.addImage((this.state.frameCount - 3) % resolution.spec.nt);
    // $("#slider-wrap").slider({ value: frameCount });
    this.setState({
      sliderValue: this.state.frameCount,

    });
    animationInterval = setTimeout(this.reverseAnimation, 300);

  };


  onSliderChange(event) {
    //console.log(event);
    //if ((this.state.isSubscribed == false || this.state.token == null) && (event >= 32)) {
    //  return

    //}
    // TODO Juan dunno why the bar itself isnt redrawing
    
    this.addImage(event);

  }

  onZoomChange = () => {
    // Only use for legacy maps. Ignore for the new vector maps as it should be setup on swellmap_v2.js.
    if(isLegacyMap) {
      const zoom = mapObj.getZoom();
      let res = 0;
      // if (zoom <= resolution.levels[0]) res = 0;
      // if (zoom <= resolution.levels[1]) res = 1;
      // if (zoom <= resolution.levels[2]) res = 2;

      let index = 0;
      for (let item of resolution.levels) {
        if (zoom <= item.max && zoom >= item.min) resolution.current = index;
        index++;
      }
      if (res < 0) {
        // mapObj.removeLayer(imageLayer);
      } else {
        if (!mapObj.hasLayer(imageLayer)) mapObj.addLayer(imageLayer);
        this.getConfig(resolution.current);
        $(".play-btn").removeClass("active");
        //clearTimeout(animationInterval);
      }

      this.setCurrentLevelLoadedState(this.isCurrentLevelLoaded())
    }
  };

  addImage = (frame) => {

    let tmpFrameCount=0;
    tmpFrameCount = Math.max(0, Math.min(resolution.spec.nt - 1, frame));
    this.setState({
      frameCount: tmpFrameCount,
    });
    if(isLegacyMap){
      const url = generateImageUrl(tmpFrameCount);
      imageLayer.setUrl(url);
    } else {
      Baird.seek(frame);
    }
   
    if (showDirectionLayer && directionLayer) {
      const url = generateImageUrl(tmpFrameCount, "direction");
      directionLayer.setUrl(url);
    }
    let start = resolution.spec[cycleVarName].split('T')[0];
    const dat = moment(start).add(tmpFrameCount * resolution.spec.dt, isLegacyMap ? "hours" : "seconds");  
    // .tz("America/Los_Angeles");
    // $("#date").html(dat.format("DD MMM YYYY"));

    $("#date").html(dat.format("DD"));
    $("#day").html(dat.format("dddd"));
    $("#time").html(dat.format("hh A"));
  };


  hideSubscribePop() {
    this.setState({
      showSubscribe: false
    })
  }


  createSliderLegend() {
    let previous = "";
    $(".slider-legend-wrap").html("");
    $(".slider-legend-wrap-date-details").html("");
    $(".slider-legend-wrap-time").html("");

    let start = resolution.spec[cycleVarName].split('T')[0];
    //console.log("ENTER createSliderLegend start",start);
    // Free version shows up to 3 days of forecast, so we keep track in order to limit the Timneline/Slider
    let daysCounter = 0;
    let sliderFrame = 1;   // Start with 1 as the look below is doing (resolution.spec.nt - 1). in case showing full 10 days need sliderFrame = resolution.spec.nt
    for (let count = 0; count < resolution.spec.nt - 1; count++) {
      const dat = moment(start).add(count * resolution.spec.dt, isLegacyMap ? "hours" : "seconds");
      //console.log("ENTER createSliderLegend date",dat.format("DD/MM hh:mm"));
      if (previous != dat.format("DD/MM")) {
        // Break loop if not subscribe. Only allow 3 days for free version
        if (daysCounter == 3 && !this.state.isSubscribed) {break;}
        daysCounter = daysCounter + 1;
        $(".slider-legend-wrap").append(
          "<div class='legend-item date'><span class='slider-legend-date'>" +
          dat.format("DD") +
          "</span><span class='slider-legend-day'>" +
          dat.format("ddd") +
          "</span></div>"
        );
        // $(".slider-legend-wrap").append(
        //   "<div class='legend-item date'></span><span class='slider-legend-day'>" +
        //     dat.format("ddd") +
        //     "</span></div>"
        // );

        $(".slider-legend-wrap-time").append(
          "<div class='legend-item date'><span class='slider-legend-day'>" +
          dat.format("ddd") +
          "</span></div>"
        );
        $(".slider-legend-wrap-date-details").append(
          "<div class='legend-item date'><span class='slider-legend-day'>" +
          dat.format("DD") +
          "</span></div>"
        );
      } else {
        $(".slider-legend-wrap").append(
          "<div class='legend-item time'>" + dat.format("DD/MM hh A") + "</div>"
        );
      }
      sliderFrame = sliderFrame + 1;
      previous = dat.format("DD/MM");
    }
    // Set slider max value
    this.setState({
      sliderMax: sliderFrame,
    });
    
  }

  getConfigUrl() {
    if (isLegacyMap) {
      return S3_BUCKET +
        "/" +
        currentFolder +
        "/" +
        mapLocation +
        "/" +
        resolution.list[resolution.current] +
        "/" +
        mapType +
        "/" +
        mapLocation +
        "_" +
        mapType +
        "_" +
        resolution.list[resolution.current] +
        ".json";
    } else {
      Baird.setGlobalUrl(S3_BUCKET + "/" + currentFolder);
      return Baird.buildUrl(newMapJsonConfig.lyr[0].rgn, newMapJsonConfig.lyr[0].til, newMapJsonConfig.img[0].nam, -1);
    }

  };

  getConfig = (res) => {
    res = Math.max(0, Math.min(resolution.list.length, res));
    // if (resolution.current !== res) {
    resolution.current = res;
    let url = this.getConfigUrl();

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .get(url, config)
      .then((result) => {
        this.initMap(result.data);
      })
      .catch((error) => {
        //console.log("ENTER getConfig error",error);
      });
  };
  initMap = (config) => {
    //console.log("ENTER initMap mapObj",mapObj);
    //console.log("ENTER initMap isLegacyMap",isLegacyMap);
    //console.log("ENTER initMap config",config);

    resolution.spec = config;
    const extent = worldfile_extents(
      config.dx,
      config.dy,
      config.ox,
      config.oy,
      config.nx,
      config.ny
    );
    // const SW = unmercator(extent[0], extent[1]);
    // const NE = unmercator(extent[2], extent[3]);
    const SW = [extent[1], extent[0]];
    const NE = [extent[3], extent[2]];

    let initMapCenterLatLong = [];
    // Hardcoding initial map center camera for nocal map. Probably could do it for all if required.
    if (isLegacyMap) {
      initMapCenterLatLong = [(SW[0] + NE[0]) / 2.0, (SW[1] + NE[1]) / 2.0];
    } else if (mapLocation == 'nocal') {
      initMapCenterLatLong = [36.9090103, -121.9895727]; // If need change manually grab lat long of wanted location (can be from google maps)
    } else if (mapLocation == 'socal') {
      initMapCenterLatLong = [33.700252, -118.374189]; // If need change manually grab lat long of wanted location (can be from google maps)
    } else {
      // From Baird swellmap_v2.js file
      initMapCenterLatLong = [(config.south + config.north)/2.0, (config.west + config.east)/2.0];
    }

    if (!mapObj) {
      L.mapbox.accessToken = mapToken;
      var uri =
        "https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=" +
        L.mapbox.accessToken;
      var opt = {
        tileSize: 512,
        // maxZoom: resolution.maxZoom,
        zoomOffset: -1,

      };

      mapObj = new L.Map("map-canvas", {
        attributionControl: false,
        zoomControl: false,

      }).addLayer(L.tileLayer(uri, opt));
      //mapObj.setView([(SW[0] + NE[0]) / 2.0, (SW[1] + NE[1]) / 2.0], resolution.currentZoom);
      mapObj.setView(initMapCenterLatLong, resolution.currentZoom);
      mapObj.on("zoomend", this.onZoomChange);
      L.control.zoom({ position: "topleft" }).addTo(mapObj);
    }

    this.createSliderLegend();
    
    if(isLegacyMap){
      if (imageLayer) mapObj.removeLayer(imageLayer);
      
      imageLayer = L.imageOverlay("", [SW, NE]).addTo(mapObj);
      if (directionLayer) mapObj.removeLayer(directionLayer);
      
      directionLayer = L.imageOverlay("", [SW, NE]).addTo(mapObj);
      
      if (spotLayer) mapObj.removeLayer(spotLayer);
      
      //this.preloadImages(this.state.sliderMax);
    } else{
      var dateTime = config.ot.replace(/\-|T/g,'').substring(0,10)
      //newMapJsonConfig['url'] = S3_BUCKET + "/" + dateTime
      Baird.setGlobalUrl(S3_BUCKET + "/" + dateTime);

      // If we have set the sliderMax pass it to the V2 helper setup JS
      if (this.state.sliderMax) newMapJsonConfig["maxFrames"] = this.state.sliderMax
      Baird.init(newMapJsonConfig,mapObj,this.preload,this.setCurrentLevelLoadedState)
    }

    this.addImage(0);
    
    
    // setInterval(() => {
    //   if (mapLocation == "oahu" && firstzoom) {
    //     mapObj.setZoom(11);
    //     firstzoom = false;
    //   }
    // });

    /*

    */
    // $("#slider-wrap").slider({
    //   min: 0,
    //   max: config.nt - 1,
    //   step: 1,
    //   slide: this.onSliderChange,
    //   value: frameCount,
    // });
    $("#zoom-level").html(resolution.current);
    $("#resolution").html(config.nx + " * " + config.ny);

    this.generateSpots();

    mapObj.on('click', (e) => {
      this.setState({ currentSpot: {}, swellHeight: [] })
    });

  };

  setCurrentLevelLoadedState(isLoaded) {
    thisRef.setState({isCurrentLevelPreloaded : isLoaded});
  }

  preloadImagesWraper() {
    this.setState({isCurrentLevelPreloaded:true});
    if (isLegacyMap) {
      this.preloadImages(this.state.sliderMax);
    } else {
      Baird.preloadImages();
    }
  }

  isCurrentLevelLoadedWraper() {
    if (isLegacyMap) {
      return this.isCurrentLevelLoaded();
    } else {
      return Baird.isCurrentLevelLoaded();
    }
  }

  isCurrentLevelLoaded() {
    return levelCache[resolution.current];
  }

  preloadImages(maxFrames) {
  
    if (!levelCache[resolution.current]) {
      levelCache[resolution.current] = true;
      let imageArray = [];
      let count = 0;
      let counter = (maxFrames) ? maxFrames : resolution.spec.nt;
      
      while (count < counter - 1) {
        imageArray.push(generateImageUrl(count));
        imageArray.push(generateImageUrl(count, "direction"));
        //imageArray.push(generateImageUrl(count, "period"));
        count++;
      }
      this.preload(imageArray);
    }
    
  }

  preload(imageArray) {
    thisRef.setState({preloadTotal:imageArray.length,preloadCount:0});
  
    imageArray.forEach( imgUrl => {
      var img=new Image();
      img.addEventListener("load", () => thisRef.resolve(img));
      img.addEventListener("error", err => thisRef.reject(err));
      img.src = imgUrl;
    })
    /*
    this.each(function () {
      setTimeout(() => {
        $("<img/>")[0].src = this;
      });
    });
    */
  }

  resolve(img) {
    //console.log("ENTER resolve",img);
    thisRef.setState({preloadCount: thisRef.state.preloadCount+1});
  }
  reject(err) {
    thisRef.setState({preloadCount: thisRef.state.preloadCount+1});
  }

  setDefault = () => {
    let newConfig = configurations[mapLocation];
    if (!newConfig) {
      newConfig = {
        default: 8,
        maxZoom: 21,
        resolutions: {
          512: "6-7",
          1024: "8-9",
          2048: "10-20"
        }
      }
    }
    let res = [];
    let levels = [];
    let index = 0;
    for (let item in newConfig.resolutions) {
      res.push(item);
      let zooms = newConfig.resolutions[item].split('-');
      levels.push({
        min: zooms[0],
        max: zooms[1]
      })
      if (newConfig.default <= zooms[1] && newConfig.default >= zooms[0]) resolution.current = index;
      index++;
    }
    resolution.levels = levels;
    resolution.list = res;
    resolution.currentZoom = newConfig.default;
    resolution.maxZoom = newConfig.maxZoom;

  }
  getLatestConfig = () => {
    const url = S3_BUCKET + ( (isLegacyMap) ? "/cycle_california.json" : "/cycle_california_v2.json");
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .get(url, config)
      .then((result) => {
        if (isLegacyMap) {
          currentFolder = result.data.currrentFolder;
          configurations = result.data;
        } else {
          currentFolder = result.data.cyc;
          // V2 config files does not return region configurations so we hardcoded them here.
          configurations = {
            "nocal": {
                "maxZoom": 12,
                "default": 10
            },
            "socal": {
                "maxZoom": 11,
                "default": 8
            },
            "nepac": {
                "maxZoom": 8,
                "default": 4
            }
          };
        }
        this.setDefault();
        this.getConfig(resolution.current);
      })
      .catch((error) => {
        //console.log("ENTER getLatestConfig error",error);
        if (error.response) {
          // Request made and server responded
          //console.log(error.response.data);

        } else if (error.request) {
          // The request was made but no response was received
          //console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          //console.log("Error", error.message);
        }
      });
  };

  fetchSwellHeight = () => {
    const params = 'waveHeight';
    const url = `https://api.stormglass.io/v2/weather/point?lat=${this.state.currentSpot.lat}&lng=${this.state.currentSpot.long}&params=${params}`
    const config = {
      headers: {
        'Authorization': '86fbbd12-372c-11e9-a7d7-0242ac130004-86fbbe48-372c-11e9-a7d7-0242ac130004'
      },
    };

    axios
      .get(url, config)
      .then((result) => {
        // var min = Math.min(...result.data.hours.map(item => item.waveHeight.sg ? item.waveHeight.sg : 1000));
        var min = 0;
        var max = Math.ceil(Math.max(...result.data.hours.map(item => item.waveHeight.sg ? item.waveHeight.sg : 0)));
        let swellHeight = result.data.hours.map(item => {
          return {
            min: min,
            max: max,
            percentage: 100 * (item.waveHeight.sg - min) / (max - min),
            height: item.waveHeight.sg,
            time: item.time
          }
        });
        this.setState({ swellHeight: swellHeight });
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          //console.log(error.response.data);
          toast.error(`${error.response.data.data}`);
          //console.log(error.response.status);
          //console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          //console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          //console.log("Error", error.message);
        }
      });
  }

  onMarkerClick = (e) => {
    let selected = spotList.filter(item => item.lat == e.latlng.lat && item.long == e.latlng.lng);
    if (selected) {
      this.setState({ currentSpot: selected[0] })
      this.fetchSwellHeight();
    }
  }

  generateSpots = () => {
    let century21icon = L.icon({
      iconUrl: mapMaker,
      iconSize: [25, 38]
    });
    // let markers = [];
    spotLayer = L.markerClusterGroup();
    for (let item of spotList) {
      // markers.push(L.marker([item.lat, item.long], { icon: century21icon }).on('click', this.onMarkerClick));
      spotLayer.addLayer( L.marker([item.lat, item.long], { icon: century21icon }).on('click', this.onMarkerClick));
    }

    if (spotLayer) mapObj.removeLayer(spotLayer);
    // spotLayer = L.layerGroup(markers).addTo(mapObj);

    mapObj.addLayer( spotLayer );
  }
  getSpots = () => {
    //console.log(spotList)
  };
  goToSubscriptionPage = () => {
    this.state.token == null
      ? toast.warning(
        "Please login to continue...",
        {
          autoClose: 4000,
        },
        this.props.history.push(`/login`)
      )
      : this.props.history.push(`/${mapLocation}/subscription`);
  };
  gotoWindyCom = () => {
    this.props.history.push(`/${mapLocation}/windy`);
  };
  goToRegion = (region) => {
    //console.log("ENTER goToRegion",region);
    // Check if region is simpleForecast and redirect accordingly
    if (region.simpleForecast) {
      this.props.history.push(`/forecast/${region.id}`);
      window.location.reload();
    } else {
      this.props.history.push(`/swell_map/${region.id}`);
      window.location.reload();
    }
  };

  myChangeHandler = () => {
    if (this.setState.frameCount = "20") {
      window.location.reload();
    }
  }



  zoomIn() {
    this.mapObj.zoomIn();
  }

  renderLocation() {
    if (mapLocation == 'socal') {
      return <title>Southern California | Surfable Maps</title>;


    } else if (mapLocation == 'nocal') {
      return <title>Northern California | Surfable Maps</title>;


    }
    else if (mapLocation == 'oahu') {
      return <title>Oahu | Surfable Maps</title>;


    }
    else if (mapLocation == 'hawaiian') {
      return <title>Hawaii| Surfable Maps</title>;

    }
  }


  renderMeta() {
    if (mapLocation == 'socal') {

      return <meta name='description' content='7-Day Southern California Surf Wave Heights, Period and Swell Forecast'></meta>;



    } else if (mapLocation == 'nocal') {
      return <meta name='description' content='7-Day Northern California Surf Wave Heights, Period and Swell Forecast'></meta>;


    }
    else if (mapLocation == 'oahu') {
      return <meta name='description' content='7-Day Oahu Surf Wave Heights, Period and Swell Forecast'></meta>;


    }
    else if (mapLocation == 'hawaiian') {
      return <meta name='description' content='7-Day Hawaiian Island Surf Wave Heights, Period and Swell Forecast'></meta>;




    }
  }


  render() {

    //console.log(this.state.isSubscribed ? 1 : 0);

    return (

      <>
        <Helmet>
          {this.renderLocation()}
          {this.renderMeta()}
        </Helmet>
        <Header />
        <div className="outer">
          {/* <div ref={el => this.mapContainer = el} className="mapContainer"/> */}
          <div className="map-wrap">
            <div id="map-canvas" ></div>
            <div className="swell-map-container">
              <div className="wrapper location-box-align">
                <div style={{ justifyContent: "flex-start" }}>
                  <div className="location-box-wrap">
                    <div
                      className="left-arrow-wrap"
                      onClick={() => this.props.history.push("/")}

                    >
                      <img src={left_arrow} onClick={() => this.props.history.push("/")} width="20px" />
                    </div>
                    <div className="loc_round">
                      {this.state.region ? this.state.region.abbreviation : "NA"}
                    </div>
                    <p className="loc_name">
                      {this.state.region ? this.state.region.name : "NA"} 
                    </p>
                    <div className="swellMapdropdownMenu" ref={this.dropDownLocationMenu} >
                      <div className="down-box" onClick={this.handleButtonClick}>

                        <img src={down_svg} width="11px" />

                      </div>
                      {this.state.open && (
                        <div className="dropdown-items">
                          <div>
                            <ul>
                              {
                                this.state.regions.map(region => 
                                  <li
                                    key={region.name}
                                    className="items"
                                    onClick={() => this.goToRegion(region)}
                                  >
                                    {region.name}
                                  </li> )
                              }
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {this.state.currentSpot && <span className="spot-name">{this.state.currentSpot.spot}</span>}
                </div>
                <div className="legend-wrap height">
                  <img style={{ width: "100%" }} src={legend_hs_ft} alt="" />
                  <img style={{ width: "100%" }} src={legend_tp_sec} alt="" />
                </div>
              </div>
              <div style={{}}>
                <div
                  className="wind-btn-wrap"
                >
                  <button
                    className="wind-Button"
                    onClick={() => this.gotoWindyCom()}
                  >
                    <BiWind className="wind-icon" color="#F76C6C" size="20px" />
                    Wind
                  </button>

                  {/* <div
                    style={{
                      marginLeft: "10px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <button className="list_btn">List</button>
                    <button className="map_btn">Map</button>
                  </div> */}
                </div>
              </div>

              <div className="wrapper" >
                <div className="option-wrap">
                  <div className="map-type-wrap" id="map-type">
                    <div
                      className="type active"
                      data-type="height"
                      onClick={() => this.changeMapType("height")}

                    >
                      Height
                    </div>
                    <div
                      className="type "
                      data-type="period"
                      onClick={() => this.changeMapType("period")}
                    >
                      Period
                    </div>
                  </div>
                  <div className="direction-title-wrap">
                    <p className="title-wrap-date">
                      <span id="date">05</span>
                      {/* <span id="day">Sunday</span> */}
                    </p>

                    <p className="title-wrap-time" id="time">
                      05 am
                    </p>

                    <div className="direction-toggle">
                      <input
                        className="styled-checkbox"
                        id="direction-toggle"
                        type="checkbox"
                        onChange={(e) => this.toggleDirection()}
                        checked={this.state.value1}

                      />
                      <label htmlFor="direction-toggle" style={{fontSize:"14px"}}>Direction</label>
                    </div>
                    <div className="direction-toggle">
                      <input
                        className="styled-checkbox"
                        id="spot-toggle"
                        type="checkbox"
                        onChange={(e) => this.toggleSpots()}
                        checked={this.state.spotCheck}

                      />
                      <label htmlFor="spot-toggle" style={{fontSize:"14px"}}>Spots</label>
                    </div>
                  </div>
                </div>
                <div className="info-box-wrap">
                  <div className="mr-1 flex-column flex-center">
                    { (this.state.isCurrentLevelPreloaded) ? (
                      <div className="title-wrap mt-1">
                        <div
                          className="play-btn"
                          onClick={(e) => (this.state.preloadCount < this.state.preloadTotal) ? null : this.reverseControl(e)}
                        >

                          <AiFillFastBackward
                            className="play_btn pointer"
                            size="45px"
                            color={(this.state.preloadCount < this.state.preloadTotal) ? "#9E9E9E" : "#F76C6C"}
                          />

                          <AiOutlinePause
                            className="pause_btn pointer"
                            size="40px"
                            color="#F76C6C"
                          />
                        </div>

                        <div
                          className="play-btn"
                          style={{position:'relative',height:'35px'}}
                          onClick={(e) => (this.state.preloadCount < this.state.preloadTotal) ? null : this.playControl(e)}>

                          { (this.state.preloadCount < this.state.preloadTotal) ? (<CircularProgress style={{position:'absolute',width:'30px',height:'30px'}}/>) : null }
                          
                          <BsPlayFill
                            className="play_btn pointer"
                            size="45px"
                            color={(this.state.preloadCount < this.state.preloadTotal) ? "#9E9E9E" : "#F76C6C"}
                            style={{position:'absolute'}}
                          />

                          <AiOutlinePause
                            className="pause_btn pointer"
                            size="40px"
                            color="#F76C6C"
                            style={{position:'absolute'}}
                          />

                        </div>
                        
                        <div
                          className="play-btn"
                          onClick={(e) => (this.state.preloadCount < this.state.preloadTotal) ? null : this.fastForwardControl(e)}
                        >

                          <AiFillFastForward
                            className="play_btn pointer"
                            size="45px"
                            color={(this.state.preloadCount < this.state.preloadTotal) ? "#9E9E9E" : "#F76C6C"}
                          />

                          <AiOutlinePause
                            className="pause_btn pointer"
                            size="40px"
                            color="#F76C6C"
                          />
                        </div>
                      </div>
                    ) : (
                      <AiFillPlayCircle
                        className="play_btn pointer"
                        size="25px"
                        color="#F76C6C"
                        onClick={(e) => this.playControl(e)}
                      />
                    ) }
                    
                    { (this.state.isCurrentLevelPreloaded) ? (<div className="f-xs text-center">{ ((this.state.preloadCount*100) / this.state.preloadTotal) | 0 }% loaded</div>) : null }
                  </div>

                  <div className="slider-container">
                    {/* <div
                      className="play-btn"
                      onClick={() => this.playControl()}
                    >
                      <AiFillPlaySquare
                        className="play_btn"
                        size="45px"
                        color="#F76C6C"
                      />
                      <AiFillPauseCircle
                        className="pause_btn"
                        size="50px"
                        color="#F76C6C"
                      />
                    </div> */}

                    <div className="slider-wrap ui-slider" id="slider-wrap">
                      <div className="slider-legend-wrap"></div>

                      <div className="play-pause-mobile">
                        <div className="slider-legend-wrap-date-details"></div>
                      </div>

                      <div className="play-pause-web">

                        <Slider
                          min={0}
                          max={this.state.sliderMax - 1}
                          value={this.state.frameCount}
                          onChange={(e) => this.onSliderChange(e)}

                          trackStyle={{
                            backgroundColor: "#FF654969",
                            height: "5.5px",
                            borderRadius: "0px",
                          }}
                          railStyle={{
                            backgroundColor: "#FF654969",
                            height: "5.5px",
                            borderRadius: "0px",
                          }}
                          step={1}
                          handleStyle={{
                            height: "16px",
                            width: "5.5px",
                            marginLeft: "0px",
                            marginTop: "-10.5px",
                            backgroundColor: "#F76C6C",
                            border: "none",
                            borderRadius: "1px",
                          }}
                          dotStyle={{
                            width: 30,
                            border: "none",
                            borderRadius: 0,
                            height: 1,
                            backgroundColor: "blue",
                          }}
                          activeDotStyle={{
                            backgroundColor: "red",
                          }}
                        // onBeforeChange={() => this.tl.pause()}
                        // onAfterChange={this.onAfterChange}
                        />

                      </div>



                      <div className="play-pause-mobile">
                        <Slider
                          min={0}
                          max={this.state.sliderMax - 1}
                          value={this.state.frameCount}
                          onChange={(e) => this.onSliderChange(e)}
                          trackStyle={{
                            backgroundColor: "#FF654969",
                            height: "1px",
                            borderRadius: "0px",
                          }}
                          railStyle={{
                            backgroundColor: "#FF654969",
                            height: "1px",
                            borderRadius: "0px",
                          }}
                          step={1}
                          handleStyle={{
                            // height: "13px",
                            // width: "4px",
                            marginLeft: "0px",
                            marginTop: "-7px",
                            backgroundColor: "#F76C6C",
                            border: "none",
                            // borderRadius: "1px",
                          }}
                          dotStyle={{
                            width: 30,
                            border: "none",
                            borderRadius: 0,
                            height: 1,
                            backgroundColor: "blue",
                          }}
                          activeDotStyle={{
                            backgroundColor: "red",
                          }}
                        // onBeforeChange={() => this.tl.pause()}
                        // onAfterChange={this.onAfterChange}
                        />
                      </div>
                      <div className="play-pause-mobile">
                        <div className="slider-legend-wrap-time"></div>
                      </div>
                    </div>
                    <div className="swell-height-wrap">
                      <div className="swell-height-legends-wrap">
                        <p>{this.state.swellHeight.length ? this.state.swellHeight[0].max + ' ft' : ''}</p>
                        <p>{this.state.swellHeight.length ? this.state.swellHeight[0].min + ' ft' : ''}</p>
                      </div>
                      <div className="height-graph-wrap">
                        {this.state.swellHeight.map((value, index) => {
                          return <div className="height-item" key={index} style={{ height: value.percentage + '%' }}></div>
                        })}
                      </div>
                    </div>
                    { (!this.state.isSubscribed || this.state.token == null) ?
                      (
                        <div className="subcribe-box-align" >
                          <div className="subscribe-box-wrap">
                            <div>

                              <p className="subscribe-box-subtext">
                                Subscribe to view 10-day forecast
                            </p>
                            </div>
                            <button
                              className="subscribe_btn"
                              // onClick={() => this.goToSubscriptionPage()}
                              onClick={() => this.props.history.push(`/subscription`)}
                            >
                              Free Trial
                          </button>
                          </div>
                        </div>
                      ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.isLoading ? (
            <div className="sweet-loading">
              <ClipLoader size={50} color={"#ffffff"} loading={true} />
            </div>
          ) : null}
          {this.state.showSubscribe ? <div className="popup-container" onClick={() => this.hideSubscribePop()}>
            <div className="popup-outer" onClick={(e) => e.stopPropagation()}>
              <img src={close} className="close-icon" onClick={() => this.hideSubscribePop()} />
              <h2>Subscribe Now!</h2>
              <p>10 day surf wave height & period maps for Southern California, Northern California and Hawaii</p>
              <button className="subscribe-btn" onClick={() => this.props.history.push(`/subscription`)}>Subscribe</button>
            </div>
          </div> : null}
        </div>
      </>
    );
  }
}
