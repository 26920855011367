// Copyright Baird Software, 2019-2022
//
// This file supports a swellmap demo using either
//   - leaflet/Mapbox raster tiles, or
//   - Mapbox GL-JS vector tiles
//
// The external API is at the end of this file
//
// dzimmer@baird.com
//
import L from "mapbox.js";
import $ from "jquery";

var g_GL = 0; // 1 = use mapbox GL, 0 = raster tiles
var g_ani = { dly: 500, frm: 0, lev: -1, spec: null };
var g_lay = {}; // raster layers
var g_cfg = null; // loaded from json
var g_url = null;
var levelCache = {};
var preload = null;
var currentLevelStateFunc = null;
var currentLevel = -1;

////////////////////////////////////////////////////////////////////////////////
// simple helpers
////////////////////////////////////////////////////////////////////////////////


// zero-pad a number and return a string
//
function pad(n, l)
{
  var s = "" + n;
  while(s.length < l) s = "0" + s;
  return s;
}


////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

function setGlobalUrl(newUrl) {
  g_url = newUrl;
}

// build the base url for swellmaps
//
function build_path(rgn, til)
{
  return g_url + "/" + rgn + "/" + til;
}

// build the base filename for swellmaps
//
function build_tag(rgn, til, img)
{
  return rgn + "_" + til + "_" + img;
}

// build the full url for a swellmap image (or JSON file if frame < 0)
//
function build_url(rgn, til, img, frm)
{
  var url = build_path(rgn, til) + "/" + img + "/" + build_tag(rgn, til, img);
  //console.log("ENTER build_url rgn " + rgn + " til " + til + " img " + img + " frm " + frm, url);
  return (frm < 0)? url + ".json" : url + "_" + pad(frm, 6) + ".png";
}


////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////


// report whether all layers have the JSON data loaded
//
function have_extents()
{
  for(var i=0; i<g_cfg.lyr.length; i++)
  {
    if(null == g_cfg.lyr[i].box) {
      return false;
    }
  }
  return true;
}


////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////


// report whether two bounding boxes overlap (box=[xmin, xmax, ymin, ymax])
//
function box_intersects(a, b, adjacent)
{
  if(adjacent === undefined) adjacent = false;

  // https://rbrundritt.wordpress.com/2009/10/03/determining-if-two-bounding-boxes-overlap/
  //
  let rabx = Math.abs(a[0] + a[1] - b[0] - b[1]);
  let raby = Math.abs(a[2] + a[3] - b[2] - b[3]); // revised order from original is equivalent

  let rax_rbx = a[1] - a[0] + b[1] - b[0];
  let ray_rby = a[3] - a[2] + b[3] - b[2];

  if(adjacent)
  {
    return (rabx <= rax_rbx) && (raby <= ray_rby); //consider edge sharing to be intersecting
  }

  return (rabx < rax_rbx) && (raby < ray_rby);
}


// report whether a layer is visble in the map window (box=[NW[XY], NE[XY], SE[XY], SW[XY]])
//
function tile_visible(box)
{
  let map_bnd = g_ani.map.getBounds();
  let map_ext = [map_bnd.getWest(), map_bnd.getEast(), map_bnd.getSouth(), map_bnd.getNorth()];
  let img_ext = [box[3][0],         box[1][0],         box[3][1],          box[1][1]         ];
  return box_intersects(img_ext, map_ext);
}


////////////////////////////////////////////////////////////////////////////////
// map helpers
////////////////////////////////////////////////////////////////////////////////


// add an image tile layer to the map, if not present
//
function add_layer(lyr, img)
{
  var url = build_url(lyr.rgn, lyr.til, img, g_ani.frm);
  var tag = build_tag(lyr.rgn, lyr.til, img);

  if(g_GL)
  {
    if(g_ani.map.getSource(tag))
    {
      g_ani.map.getSource(tag).updateImage({ url: url });
    }
    else
    {
      var src =
      {
        coordinates: lyr.box,
        type: "image",
        url: url
      };
      g_ani.map.addSource(tag, src);

      var lyr =
      {
        id: tag,
        paint: { "raster-fade-duration": 0 }, // important for animation
        source: tag,
        type: "raster"
      };
      g_ani.map.addLayer(lyr);
    }
  }
  else
  {
    if(tag in g_lay)
    {
      g_lay[tag].setUrl(url);
    }
    else
    {
      var bnd = [[lyr.box[3][1], lyr.box[3][0]], [lyr.box[1][1], lyr.box[1][0]]];
      g_lay[tag] = L.imageOverlay(url, bnd).addTo(g_ani.map);
    }
  }
}


// remove an image tile layer from the map, if present
//
function remove_layer(tag)
{
  if(g_GL)
  {
    if(g_ani.map.getLayer(tag)) g_ani.map.removeLayer(tag);
    if(g_ani.map.getSource(tag)) g_ani.map.removeSource(tag);
  }
  else
  {
    if((tag in g_lay) && g_ani.map.hasLayer(g_lay[tag]))
    {
      g_ani.map.removeLayer(g_lay[tag]);
      delete g_lay[tag];
    }
  }
}


////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////


// get the current map zoom level and display in an html element
//
function get_and_display_zoom()
{
  var zoom = g_ani.map.getZoom();
 // $("#mapResolution").html("zoom level " + zoom.toFixed(2) + "(" + g_ani.lev + ")");
  return (g_GL)? zoom : zoom - 1;
}


////////////////////////////////////////////////////////////////////////////////
// map main functions
////////////////////////////////////////////////////////////////////////////////


// update layers visible in the map view to the current animation frame
//
function update_frame(frm)
{
  if (g_ani && g_ani.spec) {
    frm = Math.max(0, Math.min(g_ani.spec.nt - 1, frm));
    g_ani.frm = frm;
    if((g_ani.lev >= 0) && have_extents())
    {
      // TODO: disable map updating (would be nice to pause render until all layers added.. don't know how)
      for(var i=0; i<g_cfg.lyr.length; i++)
      {
        if(g_cfg.lyr[i].lev == g_ani.lev)
        {
          for(var j=0; j<g_cfg.img.length; j++)
          {
            if(g_cfg.img[j].vis && tile_visible(g_cfg.lyr[i].box))
            {
              add_layer(g_cfg.lyr[i], g_cfg.img[j].nam);
            }
            else
            {
              var tag = build_tag(g_cfg.lyr[i].rgn, g_cfg.lyr[i].til, g_cfg.img[j].nam);
              remove_layer(tag);
            }
          }
        }
      }
      // TODO: enable map updating
    }
  }
}


////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////


// update layers visible in the map view to the current zoom level
//
function set_level(lev, force)
{
  if(force === undefined) force = false;

  if((lev != g_ani.lev) || force)
  {
    for(var i=0; i<g_cfg.lyr.length; i++)
    {
      if(g_cfg.lyr[i].lev == g_ani.lev)
      {
        for(var j=0; j<g_cfg.img.length; j++)
        {
          var tag = build_tag(g_cfg.lyr[i].rgn, g_cfg.lyr[i].til, g_cfg.img[j].nam);
          remove_layer(tag);
        }
      }
    }
    g_ani.lev = lev;
  }

  update_frame(g_ani.frm);
  get_and_display_zoom(); // added to include level change
}


////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////


// update layers regardless of state
//
function refresh() // there would be other ways to do this
{
  set_level(g_ani.lev, true);
}


////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////


// play animation forward in a loop
//
function play_animation()
{
  if(g_ani.play)
  {
    update_frame((g_ani.frm + 1) % g_ani.spec.nt);
    //$("#TSTEP").slider({ "value": g_ani.frm });
    setTimeout(play_animation, g_ani.dly);
  }
}


// play/pause animation
//
function toggle_animation()
{
  if(!g_ani.play)
  {
    g_ani.play = true;
    document.getElementById("toggle").src = "ovl_pause.png";
    setTimeout(play_animation, g_ani.dly);
  }
  else
  {
    g_ani.play = false;
    document.getElementById("toggle").src = "ovl_play.png";
  }
}


////////////////////////////////////////////////////////////////////////////////
// map event callbacks
////////////////////////////////////////////////////////////////////////////////


function cb_map_load_lyr(idx)
{
  var url = build_url(g_cfg.lyr[idx].rgn, g_cfg.lyr[idx].til, g_cfg.img[0].nam, -1); // json for image set

  fetch(url).then(r => r.json()).then(r => initialize_extents(r, idx));
 // $.getJSON(url, function(json) { initialize_extents(json, idx); });
}

function cb_map_load(evt)
{
  for(var i=0; i<g_cfg.lyr.length; i++)
  {
    cb_map_load_lyr(i); // caveat: inlining cb_map_load_lyr() will always use i=6
  }
}

function cb_frame_slider(ev)
{
  update_frame(ev);
}

function cb_map_resize(evt)
{
  update_frame(g_ani.frm); // resizing map can change which tiles are visible within viewport
}

function cb_map_move(evt)
{
  update_frame(g_ani.frm); // panning map can change which tiles are visible within viewport
}

function cb_map_zoom(evt) // zooming map can change which tiles are visible within viewport AND which layer to display
{
  var zoom = get_and_display_zoom();
  //console.log("ENTER cb_map_zoom zoom ",zoom);
  currentLevel = -1;
  for(var i=0; i<g_cfg.lev.length; i++)
  {
    if(zoom >= g_cfg.lev[i].min && zoom < g_cfg.lev[i].max) currentLevel = i;
  }
  if(zoom >= g_cfg.lev[g_cfg.lev.length - 1].max)
  {
    currentLevel = -1;
  } 
  if (currentLevelStateFunc) currentLevelStateFunc(isCurrentLevelLoaded());
  set_level(currentLevel);
}

function isCurrentLevelLoaded() {
  return levelCache[currentLevel];
}
/*
function preloadImages() {
  // First check if this level has been preloaded for this session
  //console.log("ENTER preloadImages lev "+ lev + " is loaded " + levelCache[lev]);
  if (!isCurrentLevelLoaded()) {
    levelCache[currentLevel] = true;
    let imageArray = [];
    let count = 0;
    // Find layer based on current level
    let layer = g_cfg.lyr.find( ele => ele.lev == currentLevel );
    if (!layer) layer = g_cfg.lyr[0];

    while (count < g_ani.spec.nt - 1) {
      imageArray.push( build_url(layer.rgn, layer.til, "height", count) );
      imageArray.push( build_url(layer.rgn, layer.til, "direction", count) );
      //imageArray.push( build_url(layer.rgn, layer.til, "period", count) );
      
      count++;
    }
    if (preload) preload(imageArray);
  }
}
*/


// Preload all layer for a given level
function preloadImages() {
  // First check if this level has been preloaded for this session
  //console.log("ENTER preloadImages lev "+ lev + " is loaded " + levelCache[lev] + " maxFrames " + g_cfg.maxFrames);
  if (!isCurrentLevelLoaded()) {
    levelCache[currentLevel] = true;
    let imageArray = [];
    let count = 0;
    // Find layer based on current level
    let layersList = g_cfg.lyr.filter( ele => ele.lev == currentLevel );
    if (!layersList) layersList = g_cfg.lyr;

    //console.log("ENTER preloadImages v2 layersList", layersList);
    
    if (layersList) {
      // Use the g_cfg.maxFrames to loop through if found. Otherwise use the maximum frame number from the config g_ani.spec.nt
      let counter = (g_cfg.maxFrames) ? g_cfg.maxFrames : g_ani.spec.nt - 1
      while (count < counter) {
        layersList.forEach(item => {
          imageArray.push( build_url(item.rgn, item.til, "height", count) );
          imageArray.push( build_url(item.rgn, item.til, "direction", count) );
          //imageArray.push( build_url(item.rgn, item.til, "period", count) );
        });
        
        count++;
      }
      if (preload) preload(imageArray);
    }
  }
}


//////////////////////////////////////////////////////////////////////////
// JSON event callbacks
//////////////////////////////////////////////////////////////////////////


// called when any layer JSON loaded.. update layers once all layer extents loaded
//
function initialize_extents(json, idx)
{
  var SW = [json.west, json.south];
  var SE = [json.east, json.south];
  var NE = [json.east, json.north];
  var NW = [json.west, json.north];
  g_cfg.lyr[idx].box = [NW, NE, SE, SW];
  if(have_extents()) update_frame(0);
}


//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////


// called when the first layer JSON loaded.. set up the map/view
//
function initialize_shaz(json, byoMap, imgPreload=null, curLevelStateFunc)
{
  currentLevelStateFunc = curLevelStateFunc;
  preload = imgPreload;
  g_cfg = json;

  var url = build_url(g_cfg.lyr[0].rgn, g_cfg.lyr[0].til, g_cfg.img[0].nam, -1); // json for first image set
  fetch(url).then(r => r.json()).then((json) =>  initialize_map(json, 0, byoMap));
}

function initialize_map(json, lev, byoMap)
{
 
  g_ani.spec = json; // need elsewhere for cycle, dt, nt
  g_ani.lev = lev;

 // mapboxgl.accessToken = tok;

  g_ani.map = byoMap;
  g_ani.map.on("load", cb_map_load);
  g_ani.map.on("zoomend", cb_map_zoom);
  g_ani.map.on('moveend', cb_map_move);
  g_ani.map.on('moveend', cb_map_resize);

 // $("#TSTEP").slider({ "max": g_ani.spec.nt - 1, "min": 0, "slide": cb_frame_slider, "step": 1, "value": g_ani.frm });
  cb_map_load(null)
  cb_map_zoom();
}


//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////




////////////////////////////////////////////////////////////////////////////////
// externals
////////////////////////////////////////////////////////////////////////////////



// toggle between 'height' and 'period' image tiles
//
function set_surface(variable) // this requires knowing the config structure... not generic
{
  if(g_cfg)
  {
    if(variable == 'height')
    {
      g_cfg.img[0].vis = true;
      g_cfg.img[1].vis = false;
    }
    else
    {
      g_cfg.img[0].vis = false;
      g_cfg.img[1].vis = true;
    }
    refresh();
  }
}


// toggle 'direction' image tiles visibility
//
function set_vector(state) // this requires knowing the config structure... not generic
{
  if(g_cfg)
  {
    g_cfg.img[2].vis = state;
    refresh();
  }
}


export default {init:initialize_shaz, seek:cb_frame_slider,showVector:set_vector,setSurface:set_surface,preloadImages:preloadImages,isCurrentLevelLoaded:isCurrentLevelLoaded,buildUrl:build_url,setGlobalUrl:setGlobalUrl}
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////


