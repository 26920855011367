import React, { Component } from "react";
import "../SubscriptionPage.css";
import "./Stripe.css";
import AppService from "../../../commons/AppService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { FaCreditCard, FaRegCreditCard,FaLock } from "react-icons/fa";
import { ReactComponent as StripeSvg } from "../../../assets/images/stripe-logo.svg";
import InputAdornment from '@mui/material/InputAdornment';

export default class PaymentMethod extends Component {

  service = AppService.getInstance();

  constructor(props) {
    super(props);

    this.state = {
      captcha:'',
      isLoading: false,
      isLoadingPayments: false,
      paymentMethods: [],
      stripeCustomer: null,
      curSubscription: this.props.subscription,
      selectedPaymentId: null,
      cardNumber: "",
      cardName: "",
      cardMonth: "",
      cardYear: "",
      cardCvc: ""
    };
  }
  componentDidMount() {
    this.initData();
  }

  initData() {
    this.setState({
      isLoadingPayments: true,
      paymentMethods: [],
      stripeCustomer: null
    });

    this.service.getPaymentMethod().then(methods => {
        //console.log("ENTER pay methods found default",methods.map(ele => ele.id).find(id => id == this.state.stripeCustomer.defaultSource));
        let tmpSelected = methods.map(ele => ele.id).find(id => (this.state.curSubscription && id == this.state.curSubscription.defaultPaymentMethod));
        this.setState({
          isLoadingPayments: false,
          paymentMethods: methods,
          selectedPaymentId: tmpSelected
        });
        // If selected payment found then trigger callback
        if (tmpSelected) this.triggerOnPaymentChange(tmpSelected)
      }).catch(error => {
        this.setState({
          isLoadingPayments: false,
          paymentMethods: [],
          stripeCustomer:null
        });
        toast.error("Oops Something Went Wrong" + ((error.message) ? error.message : error) );
      })
  }

  addPaymentMethod = () => {

  };

  numericKeyPress(event) {
    //console.log("ENTER event",event)
    if (event.charCode < 48 || event.charCode > 57) event.preventDefault();
  }

  handleClick = () => {
    // If this.state.selectedPaymentId == new then add the payment method to this stripe user
    if (this.state.selectedPaymentId == "new") {
      this.setState({
        isLoading:true
      });
      this.service.createPaymentMethod(this.state.cardNumber, this.state.cardMonth, this.state.cardYear, this.state.cardCvc, this.state.captcha)
        .subscribe(res => {
          //console.log("ENTER createPaymentMethod res",res)
          this.setState({
            isLoading:false
          });
          this.initData();
          this.triggerOnPaymentChange(res)
        },error => {
          this.setState({
            isLoading:false
          });
        });
    } else {
      this.triggerOnPaymentChange(this.state.selectedPaymentId)
    }
  };

  triggerOnPaymentChange(newValue) {
    if (this.props.OnPaymentChange) this.props.OnPaymentChange(newValue);
    this.setState({
      selectedPaymentId: newValue
    })
  }

  render() {

    return (
      <div className="flex-column">
        <div className="flex-row">
          <FaCreditCard
            color=" #1976D2"
            className="subscriptions-icon"
          />
          <div className="f-m f-bold"> { (this.state.curSubscription) ? 'Payment method' : 'Select a Payment method'}</div>
          <div className="flex-1"></div>
          <a href="https://stripe.com/" target="_blank" className="ml-2"><StripeSvg width="150px"/></a>
        </div>
        <hr style={{marginLeft:'0', marginRight:'0', marginTop:'8px'}}/>
        {
          this.state.isLoadingPayments ? (
            <CircularProgress />
          ) : this.state.paymentMethods.length > 0 
            ? (
              <div>
                <div className="flex-row flex-align-center mb-1">
                  <FaLock color=" #BDBDBD"/>
                  <span className="ml-1 f-light">Payments are secured and encripted using Stripe</span>
                </div> 
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={this.state.selectedPaymentId}
                    onChange={(event) =>
                      this.triggerOnPaymentChange(event.target.value)
                    }
                  >
                    {this.state.paymentMethods.map( (method, idx) => (
                      <div className="flex-row flex-align-center" key={idx}>
                        <FormControlLabel key={method.id} value={method.id} control={<Radio />} label={`xxxx ` + method.card.last4 + ` (${method.card.brand}) exp. ${method.card.expMon}-${method.card.expYear}`}/>
                        { (this.state.curSubscription && this.state.curSubscription.defaultPaymentMethod == method.id) ? (
                            <span key={method.id + "_dummy"} style={{marginBottom:'5px'}} className="f-s f-light">(Default payment)</span>
                        ) : null }
                        
                      </div>
                    ))}
                    <FormControlLabel key="new" value="new" control={<Radio />} label="Add a new credit card" />
                  </RadioGroup>
                </FormControl>
              </div>
            )
            : (
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={this.state.selectedPaymentId}
                  onChange={(event) =>
                    this.triggerOnPaymentChange(event.target.value)
                  }
                >
                  <FormControlLabel key="new" value="new" control={<Radio />} label="Add a new credit card" />
                </RadioGroup>
              </FormControl>
            )
        }
        { (this.state.selectedPaymentId == "new") 
            ? (
            <div className="flex-column">
              <span className="f-s f-bold mb-1">This card will be used as your default payment method</span>
              <div>
                <TextField 
                  label="Card Number" 
                  placeholder="xxxx xxxx xxxx xxxx"
                  type="text" 
                  inputProps={{maxLength:16, inputMode:'numeric'}}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><FaCreditCard /></InputAdornment>
                  }}
                  className="w-100"
                  value={this.state.cardNumber} 
                  onChange={(e) => this.setState({cardNumber:e.target.value}) }
                  onKeyPress={(e) => this.numericKeyPress(e)} />
              </div>
              <div style={{marginTop: "12px",display: "flex", flexDirection: "row", background:"transparent"}}>
                <TextField 
                  label="Expiry Month" 
                  placeholder="MM"
                  type="text" 
                  inputProps={{
                    maxLength:2,
                    inputMode:'numeric'
                  }}
                  value={this.state.cardMonth} 
                  onChange={(e) => this.setState({cardMonth:e.target.value}) }
                  onKeyPress={(e) => this.numericKeyPress(e)}
                  />
                <div style={{width: "6px"}}></div>
                <TextField 
                  label="Expiry Year" 
                  placeholder="YY"
                  type="text" 
                  inputProps={{
                    maxLength:2,
                    inputMode:'numeric'
                  }}
                  value={this.state.cardYear} 
                  onChange={(e) => this.setState({cardYear:e.target.value}) }
                  onKeyPress={(e) => this.numericKeyPress(e)}
                  />
                <div style={{width: "6px"}}></div>
                <TextField 
                  label="CVC" 
                  type="text" 
                  inputProps={{maxLength:4,inputMode:'numeric'}}
                  InputProps={{
                    endAdornment: <InputAdornment position="start"><FaRegCreditCard /></InputAdornment>
                  }}
                  value={this.state.cardCvc} 
                  onChange={(e) => this.setState({cardCvc:e.target.value}) }
                  onKeyPress={(e) => this.numericKeyPress(e)} />
              </div>

              <img src={this.service.vasat.config.host + '/' + this.service.captchaKey + '/captcha.png'} class="captcha" />
              <TextField 
                label="Enter the text you see above" 
                placeholder=""
                className="register-input"
                type={   "text" } 
                InputProps={{
                  startAdornment: <InputAdornment position="start"><FaLock /></InputAdornment>,
                }}
                onChange={(e) =>
                  this.setState({
                    captcha: e.target.value
                  })
                }
                value={this.state.captcha}
              />


              <span className="f-s">
                By providing your card information, you allow Surfable to charge your card for future payments in accordance with our  <a href="/content/terms" target="_blank">Terms of Service</a> and <a href="/content/privacy" target="_blank">Privacy Policy</a>.
              </span>



              { (this.state.isLoading) ? (<CircularProgress />) : (
                <Button 
                  variant="contained" 
                  className="mt-1"
                  onClick={this.handleClick}
                  disabled={(this.state.cardNumber && this.state.cardMonth && this.state.cardYear && this.state.cardCvc) ? false : true}>
                  Add payment method
                </Button>
              ) }
            </div>
            ) : null
        }
      </div>
    );
  }
}
