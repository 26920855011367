import React, { Component } from "react";
import "./SignIn.css";
import { BsArrowLeft } from "react-icons/bs";
import { ReactComponent as PasswordSvg } from "../../assets/images/password.svg";
import logo from "../../assets/images/logo.png";
import qs from "qs";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../dashborad/Header";
import AppService from "../../commons/AppService";

toast.configure();
export default class ForgotPasswordRedeem extends Component {

  service = AppService.getInstance();

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      emailError: false,
      response: {},
      errorMsgText: "",
      errorMsg: false,
      isLoading: true,
      focus: false,
      token: ""
    };
    this._onFocus = this._onFocus.bind(this);
  }

  _onFocus() {
    if (!this.state.focus) {
      this.setState({
        focus: true,
      });
    }
  }

  componentDidMount() {
    // Check rest password token is valid
    var tokenParam = this.props.match.params.token;
    if (tokenParam) {
      this.service.resetPasswordTokenCheck(tokenParam).then(res => {
        //console.log("ENTER resetPasswordTokenCheck success",res)
        this.setState({
          isLoading: false,
          emailError: false,
          response: res,
          token: tokenParam
        });
      }).catch(err => {
        //console.log("ENTER resetPasswordTokenCheck error",err)
        this.setState({
          isLoading: false,
          emailError: true,
          response: err
        });
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({
      isLoading: true,
    });

    this.service.resetPassword(this.state.token, this.state.email)
      .then((result) => {
        //console.log("ENTER resetPassword",result);
        this.setState({
          isLoading: false,
          response: result
        });
        toast.success(
          //"Your new password has been sent to your email address",
          "Your password have been changed successfully.",
          {
            autoClose: 5000,
          }
        );
        setTimeout(() => {
          this.props.history.push("/login");
        }, 300);
      })
      .catch((error) => {
        //console.log("ENTER Error", error);

        toast.error("Oops Something Went Wrong: " + error.message);

        this.setState({
          isLoading: false,
          response: error
        });
      });
  }

  render() {

    var errorMessage;

    if (this.state.response.status == 90011) {
      errorMessage =  <p style={{ marginBottom: "5%" }} className="error-Text">
                      Token invalid or it has expired. Please try resetting your password again.
                      </p>;
    } else if (this.state.response.status != 200) {
      errorMessage =  <p style={{ marginBottom: "5%" }} className="error-Text">
                        {this.state.response.message}
                      </p>;
    } else {
      errorMessage = "";
    }
    return (
      <>
        <Header />
        <div className="imageBackground">
          {/* <div className="overlay"></div> */}
          <div className="container">
            <div className="main">
              <div className="left-side">
                <img
                  src={logo}
                  className={"logoImg"}
                  style={{ marginBottom: "4px" }}
                />

                <p className="left-side-text">
                  <b className="lgntxt">
                    7 day surf wave height & period maps for Southern
                    California, Northern California and Hawaii
                  </b>
                </p>
                <p className="left-side-text, lgntxt">
                  Create an account to access the SURFER Swellmaps
                </p>
              </div>

              <div className="right-side">
                <p className="login-text"> Reset Your Password </p>
                
                {errorMessage}

                <div
                  className="input-container"
                  style={{
                    marginBottom: (this.state.response.status != 200) ? 0 : "5%",
                    border: (this.state.response.status != 200)
                        ? "1px solid red"
                        : this.state.focus
                        ? "3px solid #2680EB0F"
                        : "1px solid #CDDEF3",
                  }}
                >
                  <PasswordSvg className="icons" />
                  <input
                    type="password"
                    placeholder="New Password"
                    ref="email"
                    onFocus={this._onFocus}
                    onChange={(e) =>
                      this.setState({
                        email: e.target.value,
                        emailError: false,
                        errorMsg: false,
                      })
                    }
                    value={this.state.email}
                  />
                </div>

                <button
                  style={{ marginTop: "6%" }}
                  type="submit"
                  className="btn"
                  onClick={this.onSubmit.bind(this)}
                  disabled={ (this.state.response.status == 90011) ? true : false }
                >
                  Reset Password
                </button>
              </div>
            </div>
          </div>
          {this.state.isLoading ? (
            <div className="sweet-loading">
              <ClipLoader size={50} color={"#ffffff"} loading={true} />
            </div>
          ) : null}
        </div>
      </>
    );
  }
}
